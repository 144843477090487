.content-search {
    margin: 50px -27px;
    background: map-get($color, search);

    &:first-child {
        margin-top: 0 !important;
    }

    body.layout--home & {
        color: var(--magazine-text);
        background: var(--magazine-color);
    }

    form {
        position: relative;
    }

    &__image {
        img {
            width: 100%;
        }
    }

    &__content {
        padding: 55px 30px 75px;
    }

    &__headline {
        margin: 0 0 23px !important;
    }

    &__text {
        margin-bottom: 40px;
        font: map-get($font-weight, medium) 19px/28px $serif-font;
    }

    &__input {
        height: 60px;
        width: 100%;
        padding: 0 50px 0 25px;
        color: map-get($color, text);
        border: 3px solid #ECF0F2;
        border-radius: 100px;

        &::placeholder {
            color: map-get($color, text);
            opacity: 1;
        }

        &:focus {
            outline: 5px solid #ECF0F2;
        }

        body.layout--home & {
            border-color: color-mix(in srgb, var(--magazine-color), white);

            &:focus {
                outline-color: color-mix(in srgb, var(--magazine-color), white);
            }
        }
    }

    &__button {
        position: absolute;
        top: 20px;
        right: 22px;
        width: 20px;
        height: 20px;
        border: none;
        background: url("../../images/search.svg") 0 0/contain no-repeat;
        text-indent: -999em;
        cursor: pointer;
    }

    &__link {
        @include link-list();
    }
}

@include screen(medium, '.content-search') {
    margin: 80px 0 0;
    @include background(map-get($color, search));

    body.layout--home & {
        @include background(var(--magazine-color));
    }

    &__inside {
        padding-left: 100px;
        padding-right: 100px;
    }

    &--image {
        .content-search__inside {
            display: flex;
            align-items: center;
        }
    }

    &__content {
        padding: 100px 0;
    }

    &__image {
        max-height: 789px;
        margin-right: 50px;
    }

    &__input {
        padding-right: 90px;
    }

    &__button {
        top: 25px;
        right: 42px;
        width: 30px;
        height: 30px;
    }
}

@include screen(large, '.content-search') {
    &__inside {
        padding-left: 10%;
        padding-right: 10%;
    }

    &__image {
        margin-right: 110px;
    }

    &__text {
        font-size: 30px;
        line-height: 40px;
        font-weight: map-get($font-weight, regular);
    }

    &__input {
        height: 80px;
    }
}

.content-search--image {
    @include screen(max, '.content-search') {
        &__inside {
            position: relative;
            left: calc(-50vw + 650px);
            width: 100vw;
            padding-left: 0;
            padding-right: 15%;
        }
    }

    @include screen(1800, '.content-search') {
        &__inside {
            left: -250px;
            width: 1800px;
        }
    }
}

.module-search {
    padding-top: 100px;
    font-size: 20px;
    line-height: 30px;

    &__summary {
        margin: 0 0 80px;
        font-size: 30px;
        line-height: 45px;

        strong {
            font-weight: map-get($font-weight, medium);
        }
    }

    &__items {
        border-top: 1px solid map-get($color, rule);
    }

    &__item {
        padding: 40px 0;
        border-bottom: 1px solid map-get($color, rule);
    }

    &__header {
        margin-bottom: 40px;
    }

    .image_container {
        margin-bottom: 40px;

        img {
            width: 100%;
            box-shadow: 2px 5px 8px #0000004D;
        }
    }

    .add-to-cart {
        float: right;
        margin-left: 20px;

        &__button {
            padding: 0;
            border: none;
            background: none;
            cursor: pointer;
            transition: all .1s ease;

            &:hover {
                color: var(--magazine-color);
            }

            svg {
                width: 45px;
                height: 40px;
            }
        }
    }

    &__headline {
        margin: 0 0 15px;
        font: 20px/24px $serif-font !important;
    }

    &__link {
        @include link-list(10px, true);
    }

    &__article {
        &-title {
            margin: 0 !important;
            font-size: 24px !important;
            line-height: 30px !important;
        }

        &-author {
            margin: 0;
            color: map-get($color, light);
            font-size: 17px;
            line-height: 23px;
        }

        &-text {
            margin: 25px 0 0;
            font-family: $serif-font;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }

        &-preview {
            @include link();
        }

        + .module-search__article {
            margin-top: 30px;
        }
    }
}

@include screen(medium, '.module-search') {
    &__item {
        display: flex;
        padding: 80px 0;
    }

    &__header {
        width: 35%;
        padding: 0 70px;
        flex-shrink: 0;
    }

    &__article {
        + .module-search__article {
            margin-top: 60px;
        }
    }
}
