.section-main {
    font-size: 17px;
    line-height: 1.5;

    &__inside {
        padding: 0 27px;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: map-get($font-weight, medium);
        letter-spacing: 0.4px;
    }

    h1, .h1 {
        font-size: 39px;
        line-height: 1.2;

        .serif {
            font-weight: map-get($font-weight, regular);
            font-family: $serif-font;
            font-size: 1.1em;
        }
    }

    h2, .h2 {
        margin-top: 55px;
        margin-bottom: 45px;
        font-size: 20px;
        line-height: 1.25;

        + h1 {
            margin-top: -35px;
        }
    }

    h3, .h3 {
        margin-top: 55px;
        font-size: 18px;
        line-height: 1.6;
    }

    h4, .h4 {
        margin-top: 3em;
        margin-bottom: 1em;

        &:first-child {
            margin-top: 1em;
        }
    }

    hr {
        display: block;
        width: 100%;
        height: 2px;
        margin: 50px 0;
        background: map-get($color, rule);
        border: none;
        opacity: 0.5;
    }

    mark {
        background: map-get($color, mark);
    }

    .mod_article.background {
        position: relative;
        margin: 55px 0;
        padding: .1px 0 55px;

        &:before {
            content: "";
            position: absolute;
            inset: 0 -27px;
            background: map-get($color, linkbox);
            z-index: -1;
        }
    }

    .ce_text {
        font-family: $serif-font;

        h1, h2, h3, h4, h5, h6 {
            font-family: $body-font;
        }

        h2 {
            margin-bottom: 15px;
        }

        a {
            text-decoration: underline;
        }
    }

    .mod_article:first-child > .ce_text:first-child > h2 {
        margin-top: 15px;
    }

    .pagination {
        margin: 100px 0;

        p {
            display: none;
        }

        ul {
            display: flex;
            justify-content: center;
        }

        li {
            &.first,
            &.last {
                display: none;
            }
        }

        a,
        strong {
            @include link(false, true, true);
            display: block;
            margin: 0 5px;
            padding: 0 12px;
            font-weight: map-get($font-weight, regular);
            text-align: center;
            line-height: 1;

            &.next,
            &.previous {
                width: 80px;
                padding: 0 20px;
                background: url("../../images/next.svg") 50% 50% no-repeat;
                text-indent: -999em;
                transition: all .3s ease;

                &:before {
                    content: none;
                }

                &:hover {
                    background-position: 80% 50%;
                    opacity: .5;
                }
            }

            &.previous {
                transform: rotateZ(180deg);
            }

        }

        a.next,
        a.previous {

        }

        strong {
            font-weight: map-get($font-weight, medium);

            &:before {
                left: 0;
                width: 100%;
            }
        }
    }

    .desktop-only {
        display: none;
    }
}

@include screen(medium, '.section-main') {
    .content-grid-row__inside > .ce_text:first-child > h3 {
        margin-top: 0;
    }

    .mobile-only {
        display: none;
    }

    .desktop-only {
        display: initial;
    }
}

@include screen(large, '.section-main') {
    font-size: 24px;
    line-height: 1.8;

    &__inside {
        margin: 0 auto;
        padding: 0;
        max-width: #{$screen-large}px;

        .mod_article > * {
            margin-left: 70px;
            margin-right: 70px;

            &.wide {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    h2, h3, h4, h5, h6 {
        letter-spacing: 0.63px;
    }

    h1, .h1 {
        font-size: 80px;
        line-height: 1.2;
    }

    h2, .h2 {
        margin-top: 90px;
        margin-bottom: 55px;
        font-size: 33px;
        line-height: 1.2;
    }

    h3, .h3 {
        font-size: 30px;
        line-height: 1.5;
    }

    .centered {
        margin-left: 130px;
        margin-right: 130px;

        > h2 {
            margin-left: -60px;
            margin-right: -60px;
        }

        .centered {
            margin-left: 60px;
            margin-right: 60px;
        }
    }

    .ce_headline.large h1 {
        font-size: 88px;
        line-height: 1.2;
    }

    .ce_text.small {
        font-size: 20px;
        line-height: 1.5;
    }

    .mod_article:first-child > .ce_text:first-child > h2 {
        margin-top: 55px;
    }

    .mod_article.background {
        margin: 100px 0;
        padding: 45px 0 100px;

        &:before {
            inset: 0;
        }
    }
}

@include  screen($screen-large, '.section-main') {
    .mod_article.background {
        &:before {
            inset: 0 calc((-100vw + ($screen-large * 1px)) / 2);
        }
    }
}
