.home-intro {
    margin-top: 40px;
    margin-bottom: 50px;

    &__overlap {
        display: none;
    }

    &__title {
        margin: 0;
        font-size: 39px;
        line-height: 46px;
    }

    &__description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        font: map-get($font-weight, medium) 1.1em $serif-font;
    }

    &__link {
        @include link-list();
    }

    &__image {
        margin: 50px -27px;
        padding: 40px 0 40px 44px;
        background: var(--magazine-color);

        figure {
            display: inline-block;
            transform: matrix(0.99, -0.1, 0.1, 0.99, 0, 0);
            box-shadow: 2px 5px 8px #0000004D;
        }

        img {
            width: 200px;
        }
    }
}

@include screen(medium, '.home-intro') {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 120px;

    &__content,
    &__overlap {
        display: block;
        position: relative;
        overflow: hidden;
        width: 25%;
        margin: 0;

        &-inner {
            width: 200%;
        }
    }

    &__overlap {
        color: var(--magazine-text);

        &-inner {
            margin-left: -100%;
        }
    }

    &__image {
        position: relative;
        width: 50%;
        margin: 0;
        padding: 60px 0;
        text-align: center;

        figure {
            width: 70%;
        }

        img {
            width: 100%;
        }

        &:before {
            content: "";
            position: absolute;
            left: -50%;
            right: -27px;
            top: 0;
            bottom: 0;
            background: var(--magazine-color);
            z-index: -1;
        }
    }
}

@include screen(large, '.home-intro') {
    &__title {
        font-size: 96px !important;
        line-height: 110px !important;
    }

    &__image {
        padding: 90px 0 130px;

        &:before {
            right: -70px;
        }
    }
}

@include screen(max, '.home-intro') {
    &__image {
        figure {
            width: 100%;
        }

        &:before {
            left: -325px;
            right: calc(-50vw + 650px);
        }
    }
}

.home-articles {
    margin: -20px -20px 0;

    &__article {
        display: block;
        padding: 20px;

        &--video {
            position: relative;

            svg {
                position: absolute;
                inset: 235px auto auto 35px;
                width: 50px;
                height: 50px;
            }
        }

        figure {
            height: 280px;
        }
    }

    &__text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        font: map-get($font-weight, medium) 17px/27px $serif-font;
    }
}

@include screen(medium, '.home-articles') {
    margin: -20px 0 0;

    .tns-outer {
        margin: 0 -20px;
    }
}
