.messages {
    position: fixed;
    inset: 0;
    z-index: 1000;
    display: flex;
    padding: $grid-gutter;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.7);
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s ease;

    &--open {
        opacity: 1;
        pointer-events: auto;
    }

    &__inner {
        position: relative;
        padding: 100px 40px 50px 80px;
        background: #fff;
    }

    &__close {
        position: absolute;
        inset: 30px 30px auto auto;
        width: 30px;
        height: 30px;
        background: none;
        border: none;
        cursor: pointer;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: 20px;

        a,
        button {
            font-size: 20px;
            @include link();
        }
    }

    &__list {
        display: flex;
        flex-direction: column;

        .iso_confirm {
            margin: 0 0 54px -54px;
            padding-left: 54px;
            font-size: 24px;
            line-height: 1.2;
            font-weight: map-get($font-weight, medium);
            background: url("../../images/check.svg") 0 0 no-repeat;
        }
    }
}

@include screen(medium, '.messages') {
    &__inner {
        width: 760px;
        padding: 85px 100px 80px 120px;
        background: #fff;
    }

    &__actions {
        flex-direction: row;
        gap: 40px;
    }
}

@include screen(large, '.messages') {
    &__list {
        .iso_confirm {
            font-size: 33px;
        }
    }
}
