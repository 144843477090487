@import "~handorgel/lib/css/handorgel";

@include screen(large, '.ce_accordion') {
    font-size: 20px;
    line-height: 30px;
}

.handorgel {
    width: auto;
    margin: 0;
    border-collapse: collapse;
    border: none;
    border-top: 1px solid map-get($color, text);
    border-bottom: 1px solid map-get($color, text);

    + .handorgel {
        border-top: none;
    }

    .toggler {
        position: relative;
        margin: 3px 0 0;
        transition: .1s opacity ease-in-out;

        &::before {
            position: absolute;
            top: 17px;
            right: 25px;
            width: 2px;
            height: 12px;
            background: map-get($color, text);
            content: "";
            transform: rotateX(0deg);
            transition: transform .1s linear;
            pointer-events: none;
        }

        &::after {
            position: absolute;
            top: 22px;
            right: 20px;
            width: 12px;
            height: 2px;
            background: map-get($color, text);
            content: "";
            pointer-events: none;
        }

        &:hover {
            &::before,
            &::after,
            & {
                opacity: .6;
            }
        }

        button {
            overflow: hidden;
            padding-right: 40px;
        }

        &.open {
            &:before {
                transform: rotateX(90deg);
            }
        }
    }

    button {
        display: block;
        width: 100%;
        padding: 10px 20px;
        color: map-get($color, link);
        font-size: 15px;
        line-height: 26px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        background: transparent;
        border: 0;
        outline: none;
        cursor: pointer;
        transition: background .3s;
    }

    .accordion {
        padding: 0 20px;
        border: none;
        border-top: 1px solid map-get($color, text);

        > div {
            overflow: hidden;
        }
    }
}

@include screen(medium, '.handorgel') {
    margin: 0;

    button {
        font-size: 16px;
    }
}

@include screen(large, '.handorgel') {
    .toggler {
        &::before {
            top: 22px;
            right: 25px;
        }

        &::after {
            top: 27px;
            right: 20px;
        }
    }

    button {
        font-size: 18px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}
