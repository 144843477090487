.ce_hyperlink,
.ce_download {
    font-family: $serif-font;
    line-height: 1.2;

    a {
        font-family: $body-font;
        @include link();
    }

    &.center,
    hr + & {
        text-align: center;

        a {
            @include link(true, true, true);
        }
    }

    + .ce_hyperlink,
    + .ce_download {
        margin-top: .5em;
    }
}

.ce_hyperlink_headline {
    svg {
        width: 22px;
        margin-left: 8px;
        color: var(--magazine-color);
    }
}

@include screen(medium, '.ce_hyperlink_headline') {
    svg {
        width: 35px;
    }
}
