.content-quote {
    position: relative;
    padding: 100px 10%;

    &__text {
        font-family: $serif-font;
        font-size: 43px;
        line-height: 60px;
    }

    &__name {
        font-size: 17px;
        line-height: 23px;
        font-weight: map-get($font-weight, medium);
    }

    &__source {
        font-weight: map-get($font-weight, regular);
    }
}
