.content-ads {
    margin-top: 45px;

    &__slider {
        .tns-inner {
            background: #fff;
        }
    }

    &__image {
        text-align: center;
    }

    &__content {
        padding: 0 27px 50px;

        h1 {
            margin: 30px 0 5px;
            font-size: 30px;
            line-height: 38px;
        }
    }

    &__table {
        font-size: 16px;
        line-height: 1.25;
    }

    &__col {

        &--title {
            padding-top: 12px;
            font-weight: map-get($font-weight, medium);
        }

        &--body {
            padding-bottom: 12px;
            font-family: $serif-font;
            border-bottom: 1px solid map-get($color, text);
        }
    }

    &__list {
        display: none;
    }

    + .ce_download {
        margin: 45px 0 55px;
        text-align: center;

        a {
            @include link(true, true, true)
        }
    }
}

@include screen(medium, '.content-ads') {
    overflow: hidden;
    padding: 0 70px;

    &__item {
        display: flex;
    }

    &__image {
        width: 440px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        padding: 40px 8%;
    }

    &__row {
        display: flex;
        justify-content: flex-start;
    }

    &__col {
        padding: 12px;
        border-bottom: 1px solid map-get($color, text);

        &--title {
            width: 40%;
            padding-top: 12px;
            font-weight: map-get($font-weight, medium);
        }

        &--body {
            width: 60%;
            padding-bottom: 12px;
            font-family: $serif-font;
        }
    }

    &__list {
        display: block;
        margin: 100px 60px 0;

        table {
            width: 100%;
        }

        th, td {
            padding: 8px 12px;
            border-bottom: 1px solid map-get($color, text);

            &:first-child {
                width: 30%;
            }

            &:last-child {
                width: 20%;
            }
        }

        td {
            font-family: $serif-font;
        }
    }
}

@include screen(large, '.content-ads') {
    margin-left: 0 !important;
    margin-right: 0 !important;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        padding: 40px 8%;

        h1 {
            font-size: 70px;
            line-height: 95px;
            margin: 0 0 20px;
        }
    }

    &__table,
    &__list th, td {
        font-size: 20px;
        line-height: 1.5;
    }

    + .ce_download {
        margin: 80px 0;
    }
}
