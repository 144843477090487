
.flipbook {
    position: relative;
    display: inline-block;
    line-height: 0;

    figure {
        display: inline-block;
        background-color: #fff;
        transition: box-shadow .25s ease-in-out;
    }

    figure picture {
        transform-style: preserve-3d;
        perspective: 1200px;
        display: inline-block;
    }

    &:hover figure {
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
    }

    &:active figure {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        transition: box-shadow .15s ease-in-out;
    }

    figure img {
        border-radius: 0;
        -webkit-transform-origin: left;
        transform-origin: left;
        transition: transform .25s;
        cursor: pointer;
        /* height: 300px; */
    }

    &:hover figure img {
        transform: rotateY(-17deg) rotateZ(0deg);
    }

    &:active figure img {
        transform: rotateY(-14deg) rotateZ(0deg);
    }
}

.flipbook-modal {
    position: fixed;
    inset: 0 0 0 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.8);

    &__close {
        position: absolute;
        inset: 20px 20px auto auto;
        width: 30px;
        height: 30px;
        background: transparent;
        border: none;
        cursor: pointer;

        &::before,
        &::after {
            content: "";
            position: absolute;
            inset: 0 auto auto 13px;
            display: block;
            width: 4px;
            height: 30px;
            background: #ccc;
        }

        &::before {
            transform: rotateZ(45deg);
        }

        &::after {
            transform: rotateZ(-45deg);
        }

        &:hover {
            &::before,
            &::after {
                outline: 1px solid rgba(255,255,255,.3);
            }
        }
    }

    &__container,
    iframe {
        width: 100%;
        height: 100%;

        img {
            width: 99px;
            height: 99px;
            margin-top: calc(50vh - 49px);
            margin-left: calc(50vw - 49px);
        }
    }
}

body.layout--flipbook {
    overflow: hidden;
}
