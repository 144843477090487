.mod_iso_cart,
.mod_iso_checkout {
    margin-top: 30px;

    p.error {
        @extend %errorbox;
    }

    table {
        .name {
            padding-bottom: 20px;
            font-size: 24px;

            span {
                font-weight: map-get($font-weight, medium);
            }
        }

        .quantity {
            input.text {
                width: 40px;
                height: 29px;
                padding: 0;
                border: none;
                border-bottom: 2px solid map-get($color, widget);
                border-radius: 0;
                text-align: center;

                &:focus {
                    outline: none;
                    border-bottom-color: map-get($color, widget-focus);
                }
            }
        }

        .actions {
            text-align: right;

            a {
                display: inline-flex;
                gap: 10px;
                padding: 7px 0 0;
                font-size: 17px;
                line-height: 1.35;
                transition: opacity .1s ease;

                &:hover {
                    opacity: .6;
                }
            }
        }

        tr.border td {
            padding-top: 30px;
            border-top: 2px solid map-get($color, widget);
        }
    }

    .submit_container {
        display: flex;
        gap: $grid-gutter;

        button {
            @include link(true, true, true);

            &:last-child {
                margin-left: auto;
            }
        }
    }
}

@include screen(mobile-only, '.mod_iso_cart, .mod_iso_checkout') {
    table, thead, tbody, tr, th, td {
        display: block;
    }

    table {
        display: flex;
        flex-direction: column;

        .mobile-hidden {
            display: none;
        }

        .image {
            display: none;
        }

        .grandtotal {
            font-weight: map-get($font-weight, medium);
        }

        tr.product {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-bottom: 20px;

            td:nth-child(1) {
                width: 100%;
            }

            td:nth-child(2) {
                display: none;
            }

            td.actions {
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
            }

            + tr.product {
                padding-top: 20px;
                border-top: 1px solid map-get($color, widget);
            }
        }

        tr.before_border {
            padding-bottom: 30px;
        }

        tfoot tr {
            display: flex;

            td {
                flex-grow: 1;

                &.price {
                    flex-grow: 0;
                }
            }
        }
    }
}

@include screen(medium, '.mod_iso_cart, .mod_iso_checkout') {
    table {
        width: 100%;
        border-bottom: 2px solid map-get($color, widget);

        .image {
            width: 140px;
            min-width: 140px;
            padding-left: 0;
            text-align: left;
            vertical-align: top;

            .image_container {
                position: relative;
                margin-top: 10px;
                padding-left: 34px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 12px;
                    display: block;
                    width: 113px;
                    height: 130px;
                    background: url("../../images/magazin.png") 0 0/100% 100% no-repeat;
                    pointer-events: none;
                }

                img {
                    width: 100%;
                    max-width: 120px;
                }
            }
        }

        .name {
            height: 100px;
        }

        .subhead {
            height: 44px;
        }

        .price {
            width: 180px;

            div {
                display: flex;
                justify-content: space-between;
                padding-right: 20px;
            }
        }

        .quantity {
            text-align: center;
        }

        tr.border td {
            padding-top: 60px;
        }

        tr.before_border {
            padding-bottom: 0;
        }

        tr.before_border td,
        td.before_border {
            padding-bottom: 60px;
        }
    }

    .submit_container {
        margin-top: 100px;
    }
}

@include screen(large, '.mod_iso_cart, .mod_iso_checkout') {
    table {
        font-size: 20px;

        .image {
            width: 220px;
            min-width: 220px;

            .image_container {
                &:before {
                    left: 3px;
                    width: 151px;
                    height: 171px;
                }
            }
        }
    }
}

.mod_iso_checkout {
    .steps {
        ol {
            display: flex;
            margin: 50px 0;
            padding: 0;
        }

        li {
            flex: 1;
            display: flex;
            flex-direction: column;
            font-size: 12px;
            line-height: 1.16;
            text-align: center;

            &:not(:last-child)::before {
                content: "";
                position: relative;
                top: 10px;
                left: 50%;
                height: 2px;
                background-color: #e0e0e0;
                order: 1;
            }

            &::after {
                content: "";
                position: relative;
                top: 16px;
                z-index: 1;
                display: block;
                width: 10px;
                height: 10px;
                background: map-get($color, widget);
                border-radius: 50%;
                margin: 0 auto;
            }

            &.active {
                &::before {
                    top: 6px;
                }

                &::after {
                    top: 14px;
                    width: 14px;
                    height: 14px;
                    background: var(--magazine-color);
                }
            }

            &.passed {
                &:before,
                &::after {
                    background: var(--magazine-color);
                }
            }
        }
    }

    #ctrl_paymentmethod {
        display: flex;
        flex-direction: column;

        .note {
            display: block;
            font-size: .8em;

            p:first-child {
                margin-top: 0;
            }

            a {
                text-decoration: underline;
            }
        }
    }

    .info_container {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: $grid-gutter;
        line-height: 1.25;

        h3 {
            margin: 0 0 1em;
        }

        h4 {
            margin: 0 0 .5em;
        }

        a {
            text-decoration: underline;
        }

        .edit {
            float: right;
            transition: color .1s ease;

            &:hover {
                color: var(--magazine-color);
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }

        > div {
            flex-grow: 1;
            padding: 20px;
            background: map-get($color, linkbox);
        }

        .address {
            width: 100%;

            &-group {
                display: flex;
                gap: 100px;
            }
        }
    }

    .orderconditions {
        margin: 0 0 2em;
    }
}

@include screen(medium, '.mod_iso_checkout') {
    .info_container {
        gap: 1.5*$grid-gutter;
        margin-bottom: 100px;

        > div {
            padding: 40px 50px;
        }
    }

    .address_new {
        display: flex;
        flex-wrap: wrap;
        gap: 0 $grid-gutter;

        > div {
            width: calc(50% - (0.5 * $grid-gutter));
        }
    }

    .orderconditions {
        margin: 2em 0 0;
    }
}

@include screen(large, '.mod_iso_checkout') {
    .info_container {
        .edit {
            svg {
                width: 30px;
                height: 30px;
            }
        }
    }

    .address_new {
        gap: 0 2*$grid-gutter;

        > div {
            width: calc(50% - $grid-gutter);
        }
    }
}
