.section-subheader {
    position: relative;
    overflow-x: hidden;
    z-index: 10;

    &__breadcrumb {
        padding: 15px 27px 20px;
        color: map-get($color, light);
        font-size: 13px;
        line-height: 15px;

        li {
            display: inline;

            &::after {
                content: "";
                position: relative;
                top: 2px;
                display: inline-block;
                width: 6px;
                height: 12px;
                margin: 0 4px;
                background: url("../../images/breadcrumb.svg") 0 0/contain no-repeat;
            }

            &:last-child::after {
                content: none;
            }
        }

        a {
            transition: color .1s ease-in-out;

            &:hover {
                color: map-get($color, text);
            }
        }

        svg {
            position: relative;
            top: 1px;
            width: 12px;
            height: 13px;
            margin-right: 3px;
        }
    }
}

@include screen(medium, '.section-subheader') {
    &__breadcrumb {
        padding: 25px 27px 26px;
        font-size: 16px;
        line-height: 19px;

        li {
            &::after {
                width: 7px;
                height: 14px;
            }
        }

        svg {
            top: 2px;
            width: 15px;
            height: 16px;
        }
    }
}

@include screen(large, '.section-subheader') {
    max-width: 1440px;
    margin: 0 auto;

    &__breadcrumb {
        padding-left: 70px;
        padding-right: 70px;
    }
}
