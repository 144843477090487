.section-prefooter {
    color: map-get($color, footer-text);
    font-size: 13px;
    line-height: 19px;
    background: map-get($color, footer-background);

    &__inside {
        position: relative;
        padding: 50px 0;
    }

    &__top {
        position: absolute;
        top: 16px;
        right: 20px;
        width: 37px;
        height: 30px;
        text-indent: -999em;
        background: map-get($color, text) url("../../images/link-top.svg") center center no-repeat;
    }

    &__address {
        padding: 0 20px;

        h1 {
            margin: 0;
            color: #F9F9F9;
            font-size: 18px;
            font-weight: map-get($font-weight, semibold);
        }

        a:hover {
            text-decoration: underline;
        }
    }

    &__social {
        display: inline-block;
        width: 1.4em;
        height: 1.2em;
        margin-right: 6px;
        text-indent: -999em;
        background: #fff;
        transition: opacity .1s ease-in-out;

        &:hover {
            opacity: .6;
        }

        &--twitter {
            background: url("../../images/twitter.svg") 50% 50%/contain no-repeat;
        }

        &--instagram {
            background: url("../../images/instagram.svg") 50% 50%/contain no-repeat;
        }
    }

    &__about,
    &__quicklinks {
        margin-top: 45px;

        h2 {
            margin: 0;
            padding: 0 20px 11px;
            font-size: 14px;
            text-transform: uppercase;
        }

        ul {
            border-top: 1px solid map-get($color, footer-text);
        }

        li {
            border-bottom: 1px solid map-get($color, footer-text);
        }

        a {
            position: relative;
            display: block;
            padding: 12px 60px 11px 20px;
            transition: padding-left .3s linear, opacity .1s ease-in-out;

            &:after {
                position: absolute;
                right: 40px;
                top: 10px;
                width: 25px;
                height: 25px;
                opacity: 0;
                content: "";
                transition: all .3s linear;
            }

            &:hover {
                opacity: .8;

                &:after {
                    right: 20px;
                    opacity: 1;
                }
            }
        }
    }
}

@include screen(medium, '.section-prefooter') {
    overflow: hidden;
    font-size: 17px;
    line-height: 23px;

    &__inside {
        padding: 75px 0 0;
    }

    &__address {
        float: left;
        width: 50%;
        padding: 0 30px;

        h1 {
            font-size: 20px;
        }
    }

    &__about,
    &__quicklinks {
        width: 50%;
        margin: 0 0 45px 50%;
        padding: 0 30px;

        h2 {
            padding-left: 0;
            padding-right: 0;
            font-size: 16px;
        }

        a {
            padding-left: 0;
            padding-right: 0;

            &:after {
                top: 13px;
            }
        }
    }
}

@include screen(large, '.section-prefooter') {
    &__inside {
        max-width: 1260px;
        margin: 0 auto;
        padding-bottom: 80px;
        //@include clearfix;
    }

    &__top {
        display: none;
    }

    &__address {
        width: 40%;
    }

    &__about,
    &__quicklinks {
        float: left;
        width: 28%;
        margin-left: 0;
        margin-bottom: 0;
    }
}
