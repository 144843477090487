
.widget {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 0 22px;
    color: map-get($color, text);
    font-family: $serif-font;
    font-size: inherit;

    p.error {
        @extend %errorbox;
        order: 1;
    }

    > label {
        position: absolute;
        left: 19px;
        top: 0;
        padding: 0;
        color: map-get($color, placeholder);
        font-size: 14px;
        line-height: 24px;
        transition: all .2s;
        cursor: text;
    }

    label a {
        text-decoration: underline;
    }

    &.empty > label {
        top: 20px;
        font-size: 17px;
        line-height: 30px;
    }

    select {
        appearance: none;
    }

    input.text,
    input.captcha,
    select,
    textarea {
        width: 100%;
        height: 65px;
        padding: 16px 19px 8px;
        font-size: 17px;
        line-height: 30px;
        background: #fff;
        border: none;
        border-bottom: 2px solid map-get($color, widget);
        border-radius: 0;

        &:focus {
            outline: none;
            border-bottom-color: map-get($color, widget-focus);
        }
    }

    &-textarea {
        > label {
            + textarea {
                padding-top: 30px;
            }
        }
    }

    textarea {
        width: 100%;
        height: auto;
        min-height: 150px;
        resize: none;
        padding-top: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &-radio,
    &-checkbox {
        p.error {
            width: 100%;
            margin: 0 -19px;
        }

        > fieldset {
            display: flex;
            flex-wrap: wrap;
            column-gap: 30px;
            row-gap: 5px;
            padding: 0 19px !important;
            border: none;

            > legend {
                margin-left: -19px;
                margin-right: -19px;
            }
        }

        input[type="hidden"] + & > fieldset > legend {
            margin-top: 0 !important;
        }

        input[type=radio],
        input[type=checkbox] {
            position: absolute;
            opacity: 0;

            + label {
                display: block;
                position: relative;
                padding-left: 25px;
                cursor: pointer;

                &::before {
                    content: "";
                    position: absolute;
                    inset: 5px auto auto 0;
                    display: block;
                    width: 15px;
                    height: 15px;
                    background: map-get($color, widget);
                }
            }

            &:focus + label::before {
                outline: 2px solid map-get($color, widget-text);
            }
        }

        input[type=radio] {
            + label::before {
                border-radius: 50%;
            }

            &:checked + label::after {
                content: "";
                position: absolute;
                inset: 8px auto auto 3px;
                display: block;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: map-get($color, text);
            }
        }

        input[type=checkbox] {
            &:checked + label::after {
                content: "";
                position: absolute;
                inset: 8px auto auto 2px;
                display: block;
                width: 11px;
                height: 6px;
                border-left: 2px solid map-get($color, text);
                border-bottom: 2px solid map-get($color, text);
                transform: rotateZ(-45deg);
            }
        }
    }

    select {
        padding-right: 40px;
        background: url("../images/widget-select.svg") right 16px bottom 25px/16px 9px no-repeat;
    }

    &-submit {
        margin-top: 50px;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 40px;
        justify-content: center;
        align-items: center;
        font-family: $body-font;

        button, a {
            font-size: 1em;
            line-height: 1.2;
            letter-spacing: 0.48px;
            @include link(true, true, true);
        }
    }
}

@include screen(large, '.widget') {
    > label {
        font-size: 20px;
        line-height: 24px;
    }

    &.empty > label {
        font-size: 24px;
        line-height: 44px;
    }

    input.text,
    input.captcha,
    select,
    textarea {
        height: 85px;
        font-size: 24px;
        line-height: 30px;
    }

    textarea {
        min-height: 250px;
    }

    &-radio,
    &-checkbox {
        input[type=radio],
        input[type=checkbox] {
            + label {
                padding-left: 40px;

                &::before {
                    inset: 8px auto auto 0;
                    width: 25px;
                    height: 25px;
                }
            }
        }

        input[type=radio] {
            &:checked + label::after {
                inset: 14px auto auto 6px;
                width: 13px;
                height: 13px;
            }
        }

        input[type=checkbox] {
            &:checked + label::after {
                inset: 15px auto auto 6px;
                width: 13px;
                height: 8px;
            }
        }
    }

    select {
        background-position: right 16px bottom 32px;
        background-size: 20px 12px;
    }
}

.ce_form {
    margin-bottom: 100px;

    hr {
        width: 100%;
        margin: 0 0 15px;
        border-top: 1px solid map-get($color, rule);
        border-bottom: 0;
    }

    fieldset {
        margin: 0;
        padding: 0;
        border: none;

        > legend {
            font-family: $body-font;
            //font-size: 18px;
            @extend .h4;
        }

        &.radio_container,
        &.checkbox_container {
            > legend {
                margin-top: 0;
            }
        }

        @include screen(medium) {
            &.group-50-50,
            &.group-30-70,
            &.group-70-30,
            &.group-33-33-33 {
                display: grid;
                gap: 0 30px;

                > .widget.full-width {
                    grid-column: span 2;
                }
            }

            &.group-50-50 {
                grid-template-columns: 1fr 1fr;
            }

            &.group-30-70 {
                grid-template-columns: 3fr 7fr;
            }

            &.group-70-30 {
                grid-template-columns: 7fr 3fr;
            }

            &.group-33-33-33 {
                grid-template-columns: 1fr 1fr 1fr;

                > .widget.full-width {
                    grid-column: span 3;
                }
            }
        }
    }
}
