.content-grid-row {
    &--background {
        > .content-grid-row__inside {
            padding: 35px 20px;
            background: map-get($color, linkbox);

            > :first-child,
            > :first-child > :first-child {
                margin-top: 0;
            }
        }

        + .content-grid-row--background {
            margin-top: 25px;
        }
    }

    &__inside {
        > .mod_login,
        > .mod_registration {
            &:last-child .widget-submit {
                margin-bottom: 0 !important;
            }
        }
    }

    .ce_text + & {
        margin-top: 40px;
    }
}

@include screen(medium, '.content-grid-row') {
    @each $size in [3,4,6] {
        &--md#{$size} {
            > .content-grid-row__inside {
                @include grid-row;

                > * {
                    @include grid-col($size);
                }
            }
        }

        &--offset-md#{$size} > .content-grid-row__inside > * {
            @include grid-offset($size);
        }
    }

    @each $prop in [align, justify] {
        @each $value in [start, center, end] {
            &--#{$prop}-#{$value} {
                > .content-grid-row__inside {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    min-height: 100%;
                    @if ($prop == 'align') {
                        align-items: $value;
                    } @else {
                        justify-content: $value;
                    }

                    :first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    &--background {
        > .content-grid-row__inside {
            padding: 50px;
        }

        + .content-grid-row--background {
            margin-top: 0;
        }
    }
}

@include screen(large, '.content-grid-row') {
    @each $size in [3,4,6] {
        &--lg#{$size} {
            > .content-grid-row__inside {
                @include grid-row;

                > * {
                    @include grid-col($size);
                }
            }
        }

        &--offset-lg#{$size} > * {
            @include grid-offset($size);
        }
    }

    .ce_text + & {
        margin-top: 70px;
    }
}
