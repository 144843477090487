.section-header {
    &__meta {
        a {
            font-weight: map-get($font-weight, medium);
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.32px;
            transition: opacity .1s ease-in-out;

            &:hover {
                opacity: .6;
            }

            @each $icon in [about, contact, cart] {
                &.#{$icon} {
                    > span {
                        position: relative;
                        padding-left: 45px;
                        white-space: nowrap;

                        &:before {
                            content: "";
                            position: absolute;
                            top: -4px;
                            left: 0;
                            display: block;
                            width: 30px;
                            height: 25px;
                            background: url("../../images/nav--#{$icon}.svg") center center/contain no-repeat;
                        }

                        @include screen(medium) {
                            padding-left: 35px;
                        }

                        @include screen(large) {
                            padding-left: 45px;

                            &:before {
                                top: -2px;
                            }
                        }
                    }
                }
            }

            &.account {
                > span {
                    position: relative;
                    padding-left: 40px;

                    &:before {
                        content: "";
                        position: absolute;
                        top: -4px;
                        left: 0;
                        display: block;
                        width: 25px;
                        height: 25px;
                        background: url("../../images/nav--account-dark.svg") center center/contain no-repeat;

                        body.magazine--light & {
                            background: url("../../images/nav--account-light.svg") center center/contain no-repeat;
                        }
                    }

                    @include screen(large) {
                        &:before {
                            top: -2px;
                        }
                    }
                }
            }
        }
    }
}


@include screen(mobile-only) {
    body.has-overlay {
        overflow: hidden;
    }

    .section-header {
        position: relative;
        padding: 20px 27px 25px;

        &__inside {
            display: flex;
            justify-content: space-between;
        }

        &__logo {
            display: block;
            width: 170px;
            height: 34px;
            background: url("../../images/logo-mobile.svg") 0 0/contain no-repeat;
            transition: opacity .1s ease-in-out;

            &:hover {
                opacity: .6;
            }
        }

        &__toggle {
            position: relative;
            //right: 25px;
            //top: 30px;
            width: 50px;
            height: 35px;
            padding: 10px;
            background: none;
            border: none;
            cursor: pointer;

            span {
                display: block;
                width: 30px;
                height: 1.5px;
                background: #000;
                transition: all .1s ease-in-out;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    left: 10px;
                    width: 30px;
                    height: 1.5px;
                    background: #000;
                    transition: all .1s ease-in-out;
                }

                &:before {
                    top: 10px;
                }

                &:after {
                    bottom: 10px;
                }
            }

            body.has-overlay & {
                span {
                    background: transparent;

                    &:before {
                        width: 25px;
                        left: 12px;
                        top: 17px;
                        transform: rotateZ(45deg);
                    }

                    &:after {
                        width: 25px;
                        left: 12px;
                        bottom: 17px;
                        transform: rotateZ(-45deg);
                    }
                }
            }
        }

        &__overlay {
            opacity: 0;
            pointer-events: none;
            display: flex;
            flex-direction: column;
            position: fixed;
            overflow-y: auto;
            top: 80px;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 27px;
            background: #fff;
            transition: opacity .2s ease-in-out;
            z-index: 999;

            body.has-overlay & {
                opacity: 1;
                pointer-events: auto;
            }
        }

        &__navigation {
            flex-grow: 1;
            margin-bottom: 20px;

            ul {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }

            li {
                //margin-bottom: 40px;
            }

            a {
                position: relative;
                font-weight: map-get($font-weight, medium);
                font-size: 20px;
                line-height: 25px;
                letter-spacing: 0.4px;
                @include link(false);

                &.active,
                &.trail {
                    @include link(true, false);
                }
            }
        }

        &__meta {
            flex-grow: 1;

            ul {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }

            li {
                flex-grow: 1;
                margin: 10px 0;
            }

            a {
                font-weight: map-get($font-weight, medium);
                font-size: 16px;
                line-height: 18px;
                letter-spacing: 0.32px;
            }

            li.account {
                display: flex;
                align-items: flex-end;
                margin: 50px 0 0;

                > a {
                    flex-grow: 1;
                    padding: 20px;
                    display: block;
                    background: var(--magazine-color);
                    color: var(--magazine-text);
                    font-size: 17px;
                    line-height: 20px;
                    letter-spacing: 0.34px;
                    text-align: center;
                }
            }
        }
    }
}

@include screen(medium, '.section-header') {
    &__inside {
        //display: flex;
        //justify-content: space-between;
        //margin: 0 auto;
        //padding: 110px 27px 0;
    }

    &__logo {
        position: absolute;
        top: 20px;
        left: 27px;
        display: block;
        width: 200px;
        height: 50px;
        background: url("../../images/logo-mobile.svg") 0 0/contain no-repeat;
        transition: opacity .1s ease-in-out;

        &:hover {
            opacity: .6;
        }
    }

    &__toggle {
        display: none;
    }

    &__overlay {
        //flex-grow: 1;
        display: grid;
        grid-template: 100px 50px / 250px 1fr;
    }

    &__navigation {
        grid-column: 1 / 3;
        grid-row: 2 / 3;

        .level_1 {
            display: flex;
            gap: 35px;
            justify-content: space-evenly;
        }

        a {
            font-size: 25px;
            line-height: 29px;
            letter-spacing: 0.5px;
            font-weight: map-get($font-weight, medium);
            @include link(false);

            &.active,
            &.trail {
                @include link(true, false);
            }
        }
    }

    &__meta {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        display: flex;
        justify-content: flex-end;

        ul {
            display: flex;
            width: 100%;
            max-width: 640px;
        }

        li {
            flex-grow: 1;
        }

        a {
            display: block;
            padding: 26px 5px 24px;
            //font-size: 18px;
            //line-height: 21px;
            //letter-spacing: 0.36px;

            &.account {
                padding-left: 15px;
                padding-right: 15px;
                background: var(--magazine-color);
                color: var(--magazine-text);
                text-align: center;
            }
        }
    }
}

@include screen(large, '.section-header') {
    position: relative;
    max-width: 1800px;
    margin: 0 auto;
    height: 250px;

    &__inside {
        display: flex;
        justify-content: space-between;
        max-width: #{$screen-large}px;
        margin: 0 auto;
        padding: 110px 70px 0;
    }

    &__logo {
        position: static;
        width: 370px;
        height: 70px;
        background: url("../../images/logo-desktop.svg") 0 0/contain no-repeat;
    }

    &__overlay {
        display: block;
    }

    &__navigation {
        margin-top: 25px;
    }

    &__meta {
        position: absolute;
        top: 0;
        right: 0;

        ul {
            max-width: none;
        }

        a {
            padding: 23px 15px 21px;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.36px;

            &.account {
                width: 250px;
                margin-left: 30px;
                background: var(--magazine-color);
                color: var(--magazine-text);
                text-align: center;
            }
        }
    }
}
