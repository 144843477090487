.overflow-hidden {
    overflow: hidden;
}

.add-to-cart {
    &__dialog {
        position: fixed;
        inset: 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $grid-gutter;
        background: rgba(0,0,0,.7);
        opacity: 0;
        pointer-events: none;
        transition: opacity .2s ease;

        &--open {
            opacity: 1;
            pointer-events: auto;
        }

        &-inner {
            position: relative;
            width: 450px;
            max-width: 100%;
            max-height: 100%;
            padding: 80px 20px 30px;
            background: #fff;
        }

        &-scroll {
            overflow-y: auto;
            max-height: calc(100vh - 160px);
        }

        &-close {
            position: absolute;
            inset: 30px 30px auto auto;
            width: 20px;
            height: 20px;
            background: none;
            border: none;
            cursor: pointer;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__title {
        margin: 0 0 30px;
        font-size: 20px;
        line-height: 1.2;
        font-weight: map-get($font-weight, medium);
        text-align: center;
    }

    &__options {
        max-width: 300px;
        margin: 0 auto;

        legend {
            display: none;
        }

        > label {
            display: block;
            padding: 10px 0;
            font-family: $body-font;
            font-weight: map-get($font-weight, medium);
        }

        .radio_container {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            gap: 0;
            border-bottom: 2px solid map-get($color, text);
            padding: 0 !important;

            > span {
                padding: 10px;
                border-top: 2px solid map-get($color, widget);
                font-family: $body-font;
            }
        }
    }

    &__price {
        margin: 1em;
        text-align: center;
        font-family: $serif-font;
        font-size: 39px;
        line-height: 46px;
        letter-spacing: 0.4px;
    }

    &__actions {
        display: flex;
        justify-content: center;

        button {
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 25px;
            border: 2px solid map-get($color, text);
            background: none;
            cursor: pointer;
            transition: background-color .1s ease;

            &:hover {
                color: var(--magazine-text);
                background-color: var(--magazine-color);
            }

            svg {
                width: 35px;
            }

            form:invalid & {
                opacity: .6;
                cursor: default;

                &:hover {
                    color: inherit;
                    background-color: inherit;
                }
            }
        }
    }
}

@include screen(large, '.add-to-cart') {
    &__title {
        font-size: 33px;
    }

    &__dialog {
        &-inner {
            width: 760px;
            padding: 80px;
        }

        &-close {
            width: 30px;
            height: 30px;
        }
    }

    &__options {
        max-width: 450px;

        > label {
            padding: 20px 0;
        }

        .radio_container > span {
            padding: 20px;
        }
    }

    &__actions {
        button {
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 25px 50px;

            svg {
                width: 45px;
            }
        }
    }
}

.module-magazine {
    margin-top: 30px;

    &__header {
        position: relative;
        max-width: 400px;
        margin: 0 auto;

        .image_container {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -71px;
                display: block;
                width: 351px;
                height: 397px;
                background: url("../../images/magazin.png") 0 0/100% 100% no-repeat;
                pointer-events: none;
            }

            img {
                width: 100%;
                max-width: 280px;
            }
        }

        .add-to-cart__button {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px 25px;
            white-space: nowrap;
            border: 2px solid map-get($color, text);
            background-color: #fff;
            cursor: pointer;
            transition: background-color .1s ease, color .1s ease;

            svg {
                width: 45px;
                height: auto;
                margin-right: 20px;

                .check {
                    stroke: #fff;
                    transition: stroke .1s ease;
                }
            }

            &:hover {
                color: var(--magazine-text);
                background-color: var(--magazine-color);

                svg .check {
                    stroke: var(--magazine-color);
                }
            }

            &--sticky {
                position: fixed;
                z-index: 100;
                right: 20px;
                bottom: 20px;
                display: block;
                width: 65px;
                height: 65px;
                padding: 4px 0 0 !important;
                border-radius: 85px;

                span {
                    position: absolute;
                    opacity: 0;
                    font-size: 0;
                }

                svg {
                    width: 60%;
                    margin: 0;
                }
            }
        }
    }

    &__link {
        @include link-list();
    }

    &__title {
        margin-top: 0 !important;
    }

    &__description {
        margin-top: 60px;
        padding: 2em 0;
        @include background(var(--magazine-color));
        color: var(--magazine-text);

        p {
            //font: 43px/60px $serif-font;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
        }

        button {
            @include link()
        }

        &--more {
            p {
                overflow: unset;
                display: block;
                -webkit-line-clamp: unset;
            }

            button {
                display: none;
            }
        }
    }

    &__article {
        padding: 0 0 60px;

        &:last-child {
            border-bottom: none;
        }

        .image_container {
            margin-bottom: 34px;
        }

        &-title {
            margin: 0 0 30px !important;
        }

        &-text {
            font-family: $serif-font;
        }

        &-preview {
            @include link();
        }
    }

    &__author {
        margin: 50px 0;

        &-text {
            margin-bottom: 25px;
            border-top: 1px solid map-get($color, text);
        }

        img {
            border: 1px solid map-get($color, text);
        }
    }

    &__video {
        &s {
            margin: 75px 0 0;
            padding: 0 20px 75px 0;
            @include background(map-get($color, linkbox));
        }

        &-headline {
            margin-top: 0 !important;
            padding-top: 55px;
        }

        &-player {
            display: grid;
            overflow: hidden;
            max-height: 600px;
            background: #000;
            box-shadow: 20px 20px var(--magazine-color);
        }

        &-splash {
            position: relative;
            display: block;

            .aspect--16\:9 & {
                aspect-ratio: 16 / 9;
            }
            .aspect--16\:10 {
                aspect-ratio: 16 / 10;
            }
            .aspect--21\:9 {
                aspect-ratio: 21 / 9;
            }
            .aspect--4\:3 {
                aspect-ratio: 4 / 3;
            }
            .aspect--3\:2 {
                aspect-ratio: 3 / 2;
            }

            figure {
                width: 100%;
                height: 0;
            }

            img {
                width: 100%;
                height: auto;
                object-position: center center;
                object-fit: cover;
            }

            svg {
                position: absolute;
                inset: auto auto 15px 15px;
                width: 50px;
                height: 50px;
            }
        }

        &-description {
            margin-top: 50px;
            font-family: $serif-font;
        }
    }
}

@include screen(650, '.module-magazine') {
    &__header {
        max-width: none;
        margin: 0;
        padding: 0 25px;
        display: grid;
        grid: auto auto 1fr / auto 1fr;
    }

    &__image {
        grid-row: 1 / 4;
        grid-column: 1 / 2;

        .image_container {
            width: 280px;
            margin-right: 50px;
        }
    }

    &__issue {
        grid-row: 1/2;
        grid-column: 2/3;
    }

    &__title {
        grid-row: 2/3;
        grid-column: 2/3;
    }

    &__author {
        display: flex;
        gap: 30px;
        margin-top: 100px;

        &-text {
            width: 50%;
            border-top: 1px solid map-get($color, text);
        }
    }

    &__video {
        &-splash {
            svg {
                inset: auto auto 25px 25px;
                width: 100px;
                height: 100px;
            }
        }
    }
}

@include screen(medium, '.module-magazine') {
    margin-top: 90px;

    &__header {
        padding: 0 75px;

        .image_container {
            margin-right: 100px;
        }

        .add-to-cart__button {
            &--sticky {
                right: 40px;
                bottom: 40px;
                width: 85px;
                height: 85px;

                svg {
                }
            }
        }
    }

    &__description {
        padding: 75px;
    }

    &__articles {
        display: flex;
        flex-wrap: wrap;
        margin: 0 $grid-gutter * -1;
    }

    &__article {
        width: 50%;
        padding: 30px;
        font-size: 20px;
        line-height: 30px;

        &-author {
            margin: 0 0 23px;
            font-size: 17px;
            line-height: 23px;
        }
    }

    &__video {
        &s {
            display: grid;
            grid-template: auto/1fr 1fr;
            gap: 60px;
            margin: 130px auto 0;
            padding: 70px 20px 140px 0;
        }

        &-headline,
        &-container {
            max-width: 800px;
            margin: 0 auto;
        }

        &-description {
            margin-top: 70px;
        }

        &s--total1 {
            display: block;
        }
    }
}

@include screen(large, '.module-magazine') {
    &__header {
        .image_container {
            &:before {
                left: -91px;
                width: 471px;
                height: 538px;
            }

            img {
                max-width: 380px;
            }
        }

        .image_container {
            width: 380px;
        }

        .add-to-cart__button {
            padding: 25px 50px;
        }
    }

    &__link {
        margin-top: 50px;
    }

    &__title {
        font-size: 96px !important;
        line-height: 110px !important;
    }

    &__description {
        margin-top: 160px;
        padding: 140px 75px 160px;

        p {
            font: 43px/60px $serif-font;
        }
    }

    &__articles {
        margin: 0 -60px;
    }

    &__article {
        padding: 60px;
    }

    &__videos {
        column-gap: 120px;
    }
}

.module-list {
    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 50px;
        margin-bottom: 20px;

        h2 {
            margin: 0 0 20px !important;
            padding: 8px 0;
        }
    }

    &__filter {
        position: relative;
        display: none;
        float: right;
        margin-left: 50px;
        padding: 8px 40px 8px 15px;
        font-weight: map-get($font-weight, medium);
        border-bottom: 2px solid map-get($color, text);

        &.active {
            display: flex;
        }

        &::after {
            content: "";
            position: absolute;
            right: 15px;
            top: 14px;
            display: inline-block;
            width: 10px;
            height: 10px;
            border-right: 2px solid map-get($color, text);
            border-bottom: 2px solid map-get($color, text);
            transform: rotateZ(45deg);
            pointer-events: none;
        }

        h3 {
            margin: 0 !important;
        }

        select {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -27px;
    }

    &__item {
        margin: 35px 45px;
    }

    .image_container {
        position: relative;
        margin-bottom: 30px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -71px;
            display: block;
            width: 351px;
            height: 397px;
            background: url("../../images/magazin.png") 0 0/100% 100% no-repeat;
            pointer-events: none;
        }

        img {
            width: 280px;
            max-width: none;
        }
    }

    .add-to-cart {
        float: right;
        margin-left: 20px;

        &__button {
            padding: 0;
            border: none;
            background: none;
            cursor: pointer;
            transition: all .1s ease;

            &:hover {
                color: var(--magazine-color);
            }

            svg {
                width: 45px;
                height: 40px;
            }
        }
    }

    &__name {
        margin: 0 0 12px !important;
        font: 16px/26px $serif-font !important;
    }

    &__link {
        @include link-list(10px, true);
    }
}

@include screen(medium, '.module-list') {
    &__header {
        margin-top: 90px;
        margin-bottom: 50px;
    }

    &__items {
        display: grid;
        grid: auto-flow / 1fr 1fr;
        margin-left: 0;
        margin-right: 0;
    }

    &__item {
        max-width: 280px;
        margin: 40px 50px;
    }

    &__name {
        font: 20px/24px $serif-font !important;
    }
}

@include screen(large, '.module-list') {
    &__headline {
        margin-left: -70px;
        margin-right: -70px;
    }

    &__filter {
        padding-right: 80px;

        &::after {
            width: 20px;
            height: 20px;
        }
    }

    &__items {
        grid: auto-flow / 1fr 1fr 1fr;
    }
}

@include screen(max, '.module-list') {
    &__item {
        margin-left: 70px;
        margin-right: 70px;
    }
}

.product-gallery {
    display: flex;
    justify-content: center;

    > div {
        position: relative;
        margin: 60px 40px 120px;
    }

    figure {
        display: inline-block;
        position: relative;
        box-shadow: 2px 5px 8px #0000004D;
        transform: matrix(1, -0.02, 0.02, 1, 0, 0);
        z-index: 2;

        &:first-child {
            position: absolute;
            top: 20px;
            left: -20px;
            transform: matrix(1, -0.09, 0.09, 1, 0, 0);
            z-index: 3;
        }

        &:last-child {
            position: absolute;
            top: -20px;
            left: 20px;
            transform: matrix(1, 0.05, -0.05, 1, 0, 0);
            z-index: 1;
        }
    }
}
