$body-font: unquote('"Work Sans", sans-serif');
$serif-font: unquote('"freight-text-pro", serif');
$screen-large: 1440;

$color: (
    text: #002d40,
    placeholder: #818484,
    light: #818484,
    linkbox: #FAF8F8,
    dots: #E3E3E3,
    rule: #B9B9B9,
    footer-text: #EBEBEB,
    footer-background: #6A7780,
    search: #DFE2E3,
    mark: #D3F1FE,
    widget: #E3E3E3,
    widget-focus: #012B3D,
    success: #64d564,
    warning: #EBD47F,
    error: #ff4444,
);

:root {
    --magazine-color: #EBD47F;
    --magazine-text: #002d40;
}

@mixin screen($size, $block: null) {
    $sizes: (
        mobile-only: "max-width: 767px",
        medium: "min-width: 768px",
        //tablet-only: "min-width: 768px) and (max-width: 1219px",
        large: "min-width: 1220px",
        max: "min-width: 1440px",
    );

    @if (map-has_key($sizes, $size)) {
        $size: map-get($sizes, $size);
    } @else if (type_of($size) == 'number') {
        $size: "min-width: #{$size}px";
    }

    @media screen and (#{unquote($size)}) {
        @if ($block) {
            #{$block} {
                @content;
            }
        } @else {
            @content;
        }
    }
}

@mixin link-list($gap: 20px, $fontSize: false) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $gap;
    margin: $gap 0 0;

    a, button {
        @include link();

        @if ($fontSize) {
            font-size: 16px;
            line-height: 18px;
        }
    }

    @if ($fontSize) {
        @include screen(medium) {
            a, button {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
}

@mixin link($visible: true, $hover: true, $center: false) {
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: map-get($font-weight, medium);
    text-align: left;

    &:before {
        content: "";
        position: absolute;
        bottom: -10px;
        height: 2px;
        background: currentColor;

        @if $center {
            left: 25%;
            transition: width .2s ease-in-out, left .2s ease-in-out;
        } @else {
            left: 0;
            transition: width .2s ease-in-out;
        }

        @if $visible {
            width: 50%;
        } @else {
            width: 0;
        }
    }

    @if $hover {
        &:hover {
            &:before {
                @if $center {
                    left: 0;
                    width: 100%;
                } @else if $visible {
                    width: 100%;
                } @else {
                    width: 50%;
                }
            }
        }
    }
}

@mixin background($color) {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: -27px;
        right: -27px;
        top: 0;
        bottom: 0;
        background: $color;
        z-index: -1;

        @include screen(large) {
            left: -70px;
            right: -70px;
        }

        @include screen(max) {
            left: calc(-50vw + 650px);
            right: calc(-50vw + 650px);
        }
    }
}

%errorbox,
%successbox {
    display: block;
    margin-bottom: 2em;
    padding: 10px 20px;
    font-size: 0.9em;
    line-height: 1.25;
    border-radius: 2px;
}

%errorbox {
    background: map-get($color, error);
}

%successbox {
    background: map-get($color, success);
}
