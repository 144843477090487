.content-linkbox {
    background: map-get($color, linkbox);

    &--magazine-color {
        color: var(--magazine-text);
        background: var(--magazine-color);
    }

    &__image {
        img {
            width: 100%;
        }
    }

    &__content {
        padding: 35px 20px 50px;
    }

    &__headline {
        margin: 0;
        font-weight: map-get($font-weight, regular) !important;
    }

    &__text {
        font-family: $serif-font;
        font-size: 1.1em;
    }

    &__link {
        a {
            @include link();
        }
    }
}

@include screen(medium, '.content-linkbox') {
    display: flex;
    justify-content: space-between;
    margin: 0 !important;

    &__image {
        width: 35%;
        padding: 30px 0 30px 30px;

        img {
            box-shadow: 20px 20px var(--magazine-color);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 65%;
        padding-left: 30px;
        padding-right: 30px;
    }

    &--borderless {
        .content-linkbox__image {
            padding: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                box-shadow: none;
            }
        }
    }

    &--right {
        flex-direction: row-reverse;

        .content-linkbox {
            &__image {
                //text-align: right;
                //
                //picture {
                //    right: 0;
                //    left: auto;
                //}
            }
        }
    }
}

@include screen(large, '.content-linkbox') {
    &__image {
        padding: 70px 0 70px 70px;
    }

    &__content {
        padding: 70px;
    }
}
