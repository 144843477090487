.tns-outer {
    position: relative;
    //overflow: hidden;

    .tns-ovh {
        padding-bottom: 30px;
    }

    .tns-nav {
        position: absolute;
        z-index: 100;
        inset: auto 0 0;
        display: flex;
        justify-content: center;
        gap: 20px;

        button {
            width: 12px;
            height: 12px;
            background: map-get($color, dots);
            border: none;
            border-radius: 50%;

            &.tns-nav-active {
                background: var(--magazine-color);
            }
        }
    }

    .tns-controls {
        display: none;
    }
}

@include screen(medium, '.tns-outer') {
    .tns-ovh {
        padding-bottom: 50px;
    }

    .tns-controls {
        display: flex;
        justify-content: space-between;
        position: absolute;
        inset: calc(50% - 65px) -65px 0;
        pointer-events: none;

        button {
            width: 30px;
            height: 55px;
            border: none;
            background: url("../../images/slider-control.svg") 0 0 no-repeat;
            text-indent: -999em;
            transition: opacity .1s ease-in-out;
            pointer-events: auto;

            &:hover {
                opacity: .6;
            }

            &:disabled {
                opacity: .2;
            }

            &:last-child {
                transform: rotateY(180deg);
            }
        }
    }
}

@include screen(large, '.tns-outer') {
    .tns-ovh {
        padding-bottom: 80px;
    }

    .tns-nav {
        button {
            width: 22px;
            height: 22px;
        }
    }
}
