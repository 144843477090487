
.account-navigation {
    margin-bottom: 45px;

    ul {
        display: flex;
        gap: 10px;
        flex-direction: column;
    }

    a {
        display: block;
        padding: 7px 20px;
        font-size: 14px;
        line-height: 1.3;
        border: 1px solid transparent;
        border-radius: 100px;

        &:hover,
        &.active,
        &.trail {
            border-color: var(--magazine-color);
        }
    }

    @include screen(medium) {
        ul {
            flex-direction: row;
        }

        a {
            font-size: 18px;
        }
    }

    @include screen(large) {
        margin-left: 70px;
        margin-right: 70px;
    }
}

.mod_iso_orderhistory {
    max-width: 960px;
    margin-left: auto !important;
    margin-right: auto !important;

    .order-status {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }

    .toggler,
    .toggler > button {
        display: flex;
        align-items: center;

        .order-status {
            flex-shrink: 0;
            width: 14px;
            height: 14px;
            margin-left: -16px;
            margin-right: 10px;
        }

        .order-title {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .order-details {
        margin: 2em 0;
        border-bottom: 1px solid var(--magazine-color);

        &__info {
            > div {
                margin-bottom: .5em;

                > div:first-child {
                    flex-shrink: 0;
                    width: 200px;
                    font-weight: map-get($font-weight, semibold);
                }
            }
        }

        &__item {
            display: flex;
            gap: 30px;
            padding: 30px 0;
            border-top: 1px solid var(--magazine-color);
        }

        &__edition {
        }

        &__headline {
            font-weight: map-get($font-weight, medium);
        }

        &__download {
            margin-top: 0.5em;
            font-size: 0.8em;

            &--available {
                @include link();
            }

            &--remaining {
                display: inline-block;
                padding: 0 10px;
                background: map-get($color, warning);
            }
        }
    }

    @include screen(medium) {
        .order-details {
            margin: 50px 10px;

            &__info {
                > div {
                    display: flex;

                    > div:first-child {
                        flex-shrink: 0;
                        width: 200px;
                        font-weight: map-get($font-weight, semibold);
                    }
                }
            }
        }
    }
}

.mod_login,
.mod_personalData,
.mod_changePassword,
.mod_two_factor {
    p.tl_confirm {
        @extend %successbox;
    }

    p.error,
    p.tl_error {
        @extend %errorbox;
    }
}

.mod_two_factor {
    .submit_container {
        @include link-list();
    }
}
