@import url("https://use.typekit.net/bey8mwp.css");
:root {
  --magazine-color: #EBD47F;
  --magazine-text: #002d40;
}

.mod_iso_cart p.error,
.mod_iso_checkout p.error, .mod_login p.error,
.mod_login p.tl_error,
.mod_personalData p.error,
.mod_personalData p.tl_error,
.mod_changePassword p.error,
.mod_changePassword p.tl_error,
.mod_two_factor p.error,
.mod_two_factor p.tl_error, .widget p.error,
.mod_login p.tl_confirm,
.mod_personalData p.tl_confirm,
.mod_changePassword p.tl_confirm,
.mod_two_factor p.tl_confirm {
  display: block;
  margin-bottom: 2em;
  padding: 10px 20px;
  font-size: 0.9em;
  line-height: 1.25;
  border-radius: 2px;
}

.mod_iso_cart p.error,
.mod_iso_checkout p.error, .mod_login p.error,
.mod_login p.tl_error,
.mod_personalData p.error,
.mod_personalData p.tl_error,
.mod_changePassword p.error,
.mod_changePassword p.tl_error,
.mod_two_factor p.error,
.mod_two_factor p.tl_error, .widget p.error {
  background: #ff4444;
}

.mod_login p.tl_confirm,
.mod_personalData p.tl_confirm,
.mod_changePassword p.tl_confirm,
.mod_two_factor p.tl_confirm {
  background: #64d564;
}

@font-face {
  font-family: "Work Sans";
  src: local("Work Sans Bold"), local("WorkSans-Bold"), url("../fonts/WorkSans-Bold.woff2") format("woff2"), url("../fonts/WorkSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans Black"), local("WorkSans-Black"), url("../fonts/WorkSans-Black.woff2") format("woff2"), url("../fonts/WorkSans-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans Black Italic"), local("WorkSans-BlackItalic"), url("../fonts/WorkSans-BlackItalic.woff2") format("woff2"), url("../fonts/WorkSans-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans SemiBold"), local("WorkSans-SemiBold"), url("../fonts/WorkSans-SemiBold.woff2") format("woff2"), url("../fonts/WorkSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans Italic"), local("WorkSans-Italic"), url("../fonts/WorkSans-Italic.woff2") format("woff2"), url("../fonts/WorkSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans Medium"), local("WorkSans-Medium"), url("../fonts/WorkSans-Medium.woff2") format("woff2"), url("../fonts/WorkSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans Light Italic"), local("WorkSans-LightItalic"), url("../fonts/WorkSans-LightItalic.woff2") format("woff2"), url("../fonts/WorkSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans ExtraBold"), local("WorkSans-ExtraBold"), url("../fonts/WorkSans-ExtraBold.woff2") format("woff2"), url("../fonts/WorkSans-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans Medium Italic"), local("WorkSans-MediumItalic"), url("../fonts/WorkSans-MediumItalic.woff2") format("woff2"), url("../fonts/WorkSans-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans Light"), local("WorkSans-Light"), url("../fonts/WorkSans-Light.woff2") format("woff2"), url("../fonts/WorkSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans ExtraBold Italic"), local("WorkSans-ExtraBoldItalic"), url("../fonts/WorkSans-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/WorkSans-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans Regular"), local("WorkSans-Regular"), url("../fonts/WorkSans-Regular.woff2") format("woff2"), url("../fonts/WorkSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans SemiBold Italic"), local("WorkSans-SemiBoldItalic"), url("../fonts/WorkSans-SemiBoldItalic.woff2") format("woff2"), url("../fonts/WorkSans-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans Thin"), local("WorkSans-Thin"), url("../fonts/WorkSans-Thin.woff2") format("woff2"), url("../fonts/WorkSans-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans ExtraLight"), local("WorkSans-ExtraLight"), url("../fonts/WorkSans-ExtraLight.woff2") format("woff2"), url("../fonts/WorkSans-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans Bold Italic"), local("WorkSans-BoldItalic"), url("../fonts/WorkSans-BoldItalic.woff2") format("woff2"), url("../fonts/WorkSans-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans ExtraLight Italic"), local("WorkSans-ExtraLightItalic"), url("../fonts/WorkSans-ExtraLightItalic.woff2") format("woff2"), url("../fonts/WorkSans-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans Thin Italic"), local("WorkSans-ThinItalic"), url("../fonts/WorkSans-ThinItalic.woff2") format("woff2"), url("../fonts/WorkSans-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}

input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: 99%;
  border-radius: 0;
}

textarea,
button,
input[type=email],
input[type=tel],
input[type=text],
input[type=submit],
input[type=search] {
  -webkit-appearance: none;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  display: none;
}

textarea[disabled],
button[disabled],
input[type=email][disabled],
input[type=tel][disabled],
input[type=text][disabled],
input[type=submit][disabled],
input[type=search][disabled] {
  cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type=checkbox][disabled] + label,
input[type=radio][disabled] + label {
  cursor: not-allowed;
}

table {
  font-size: inherit;
  border-collapse: collapse;
  border-spacing: 0;
}

table td, table th {
  padding: 4px 15px;
  vertical-align: top;
}

th {
  font-weight: 500;
}

caption,
strong,
b {
  font-weight: 600;
}

ul ul {
  margin-bottom: 0;
}

form,
figure,
blockquote {
  margin: 0;
  padding: 0;
}

img,
video,
iframe {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: middle;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption,
turbo-frame {
  display: block;
}

html {
  font-size: 16px;
}

body {
  line-height: 1.625;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #002d40;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: none;
}

caption,
th,
td {
  text-align: left;
  text-align: start;
}

abbr,
acronym {
  font-variant: normal;
  border-bottom: 1px dotted #002d40;
  cursor: help;
}

blockquote,
q {
  quotes: none;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

del {
  text-decoration: line-through;
}

ins {
  text-decoration: none;
}

pre,
code {
  font-family: monospace;
}

/* Vertical align */
.float_left {
  float: left;
}

.float_right {
  float: right;
}

/* Clear floats */
.clear,
#clear {
  height: 0.1px;
  font-size: 0.1px;
  line-height: 0.1px;
  clear: both;
}

/* Hide invisible elements */
.invisible,
.tns-visually-hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.aspect {
  position: relative;
}

.aspect iframe,
.responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aspect--16\:9 {
  aspect-ratio: 16/9;
}

.aspect--16\:10 {
  aspect-ratio: 16/10;
}

.aspect--21\:9 {
  aspect-ratio: 21/9;
}

.aspect--4\:3 {
  aspect-ratio: 4/3;
}

.aspect--3\:2 {
  aspect-ratio: 3/2;
}

.aspect--1\:1 {
  aspect-ratio: 1/1;
}

.handorgel .accordion {
  display: none;
  overflow: hidden;
  height: 0;
  transition: height 0.1s ease 0.1s;
}
.handorgel .accordion.open {
  display: block;
  transition: height 0.2s ease;
}
.handorgel .accordion.active {
  overflow: visible;
}

.nav-list ul, .section-social__inside ul, .nav-list li, .section-social__inside li,
.mod_navigation ul,
.mod_navigation li,
.mod_changelanguage ul,
.mod_changelanguage li,
.mod_breadcrumb ul,
.mod_breadcrumb li,
.pagination ul,
.pagination li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ce_download .size,
.ce_downloads .size {
  display: none;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px;
}
.row > * {
  padding-left: 25px;
  padding-right: 25px;
}
.row--center {
  justify-content: center;
  text-align: center;
}
.row--middle {
  align-items: center;
}
.row--bottom {
  align-items: flex-end;
}
@media screen and (min-width: 768px) {
  .row max-width--reverse-mobile {
    flex-direction: column-reverse;
  }
}

.col {
  padding-left: 25px;
  padding-right: 25px;
}
.col--center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.col--right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.col-1 {
  padding-left: 25px;
  padding-right: 25px;
  width: 8.3333333333%;
}

.offset-1 {
  margin-left: 8.3333333333% !important;
}

.col-2 {
  padding-left: 25px;
  padding-right: 25px;
  width: 16.6666666667%;
}

.offset-2 {
  margin-left: 16.6666666667% !important;
}

.col-3 {
  padding-left: 25px;
  padding-right: 25px;
  width: 25%;
}

.offset-3 {
  margin-left: 25% !important;
}

.col-4 {
  padding-left: 25px;
  padding-right: 25px;
  width: 33.3333333333%;
}

.offset-4 {
  margin-left: 33.3333333333% !important;
}

.col-5 {
  padding-left: 25px;
  padding-right: 25px;
  width: 41.6666666667%;
}

.offset-5 {
  margin-left: 41.6666666667% !important;
}

.col-6 {
  padding-left: 25px;
  padding-right: 25px;
  width: 50%;
}

.offset-6 {
  margin-left: 50% !important;
}

.col-7 {
  padding-left: 25px;
  padding-right: 25px;
  width: 58.3333333333%;
}

.offset-7 {
  margin-left: 58.3333333333% !important;
}

.col-8 {
  padding-left: 25px;
  padding-right: 25px;
  width: 66.6666666667%;
}

.offset-8 {
  margin-left: 66.6666666667% !important;
}

.col-9 {
  padding-left: 25px;
  padding-right: 25px;
  width: 75%;
}

.offset-9 {
  margin-left: 75% !important;
}

.col-10 {
  padding-left: 25px;
  padding-right: 25px;
  width: 83.3333333333%;
}

.offset-10 {
  margin-left: 83.3333333333% !important;
}

.col-11 {
  padding-left: 25px;
  padding-right: 25px;
  width: 91.6666666667%;
}

.offset-11 {
  margin-left: 91.6666666667% !important;
}

.col-12 {
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
}

.offset-12 {
  margin-left: 100% !important;
}

@media screen and (min-width: 768px) {
  .col-md-1 {
    padding-left: 25px;
    padding-right: 25px;
    width: 8.3333333333%;
  }
  .offset-md-1 {
    margin-left: 8.3333333333% !important;
  }
  .col-md-2 {
    padding-left: 25px;
    padding-right: 25px;
    width: 16.6666666667%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667% !important;
  }
  .col-md-3 {
    padding-left: 25px;
    padding-right: 25px;
    width: 25%;
  }
  .offset-md-3 {
    margin-left: 25% !important;
  }
  .col-md-4 {
    padding-left: 25px;
    padding-right: 25px;
    width: 33.3333333333%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333% !important;
  }
  .col-md-5 {
    padding-left: 25px;
    padding-right: 25px;
    width: 41.6666666667%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667% !important;
  }
  .col-md-6 {
    padding-left: 25px;
    padding-right: 25px;
    width: 50%;
  }
  .offset-md-6 {
    margin-left: 50% !important;
  }
  .col-md-7 {
    padding-left: 25px;
    padding-right: 25px;
    width: 58.3333333333%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333% !important;
  }
  .col-md-8 {
    padding-left: 25px;
    padding-right: 25px;
    width: 66.6666666667%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667% !important;
  }
  .col-md-9 {
    padding-left: 25px;
    padding-right: 25px;
    width: 75%;
  }
  .offset-md-9 {
    margin-left: 75% !important;
  }
  .col-md-10 {
    padding-left: 25px;
    padding-right: 25px;
    width: 83.3333333333%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333% !important;
  }
  .col-md-11 {
    padding-left: 25px;
    padding-right: 25px;
    width: 91.6666666667%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667% !important;
  }
  .col-md-12 {
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
  }
  .offset-md-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 1220px) {
  .col-lg-1 {
    padding-left: 25px;
    padding-right: 25px;
    width: 8.3333333333%;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333% !important;
  }
  .col-lg-2 {
    padding-left: 25px;
    padding-right: 25px;
    width: 16.6666666667%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667% !important;
  }
  .col-lg-3 {
    padding-left: 25px;
    padding-right: 25px;
    width: 25%;
  }
  .offset-lg-3 {
    margin-left: 25% !important;
  }
  .col-lg-4 {
    padding-left: 25px;
    padding-right: 25px;
    width: 33.3333333333%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333% !important;
  }
  .col-lg-5 {
    padding-left: 25px;
    padding-right: 25px;
    width: 41.6666666667%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667% !important;
  }
  .col-lg-6 {
    padding-left: 25px;
    padding-right: 25px;
    width: 50%;
  }
  .offset-lg-6 {
    margin-left: 50% !important;
  }
  .col-lg-7 {
    padding-left: 25px;
    padding-right: 25px;
    width: 58.3333333333%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333% !important;
  }
  .col-lg-8 {
    padding-left: 25px;
    padding-right: 25px;
    width: 66.6666666667%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667% !important;
  }
  .col-lg-9 {
    padding-left: 25px;
    padding-right: 25px;
    width: 75%;
  }
  .offset-lg-9 {
    margin-left: 75% !important;
  }
  .col-lg-10 {
    padding-left: 25px;
    padding-right: 25px;
    width: 83.3333333333%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333% !important;
  }
  .col-lg-11 {
    padding-left: 25px;
    padding-right: 25px;
    width: 91.6666666667%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667% !important;
  }
  .col-lg-12 {
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
  }
  .offset-lg-12 {
    margin-left: 100% !important;
  }
}
.widget {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 0 22px;
  color: #002d40;
  font-family: "freight-text-pro", serif;
  font-size: inherit;
}
.widget p.error {
  order: 1;
}
.widget > label {
  position: absolute;
  left: 19px;
  top: 0;
  padding: 0;
  color: #818484;
  font-size: 14px;
  line-height: 24px;
  transition: all 0.2s;
  cursor: text;
}
.widget label a {
  text-decoration: underline;
}
.widget.empty > label {
  top: 20px;
  font-size: 17px;
  line-height: 30px;
}
.widget select {
  appearance: none;
}
.widget input.text,
.widget input.captcha,
.widget select,
.widget textarea {
  width: 100%;
  height: 65px;
  padding: 16px 19px 8px;
  font-size: 17px;
  line-height: 30px;
  background: #fff;
  border: none;
  border-bottom: 2px solid #E3E3E3;
  border-radius: 0;
}
.widget input.text:focus,
.widget input.captcha:focus,
.widget select:focus,
.widget textarea:focus {
  outline: none;
  border-bottom-color: #012B3D;
}
.widget-textarea > label + textarea {
  padding-top: 30px;
}
.widget textarea {
  width: 100%;
  height: auto;
  min-height: 150px;
  resize: none;
  padding-top: 0;
}
.widget input[type=number] {
  -moz-appearance: textfield;
}
.widget input[type=number]::-webkit-outer-spin-button, .widget input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.widget-radio p.error, .widget-checkbox p.error {
  width: 100%;
  margin: 0 -19px;
}
.widget-radio > fieldset, .widget-checkbox > fieldset {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 5px;
  padding: 0 19px !important;
  border: none;
}
.widget-radio > fieldset > legend, .widget-checkbox > fieldset > legend {
  margin-left: -19px;
  margin-right: -19px;
}
input[type=hidden] + .widget-radio > fieldset > legend, input[type=hidden] + .widget-checkbox > fieldset > legend {
  margin-top: 0 !important;
}
.widget-radio input[type=radio],
.widget-radio input[type=checkbox], .widget-checkbox input[type=radio],
.widget-checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
}
.widget-radio input[type=radio] + label,
.widget-radio input[type=checkbox] + label, .widget-checkbox input[type=radio] + label,
.widget-checkbox input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}
.widget-radio input[type=radio] + label::before,
.widget-radio input[type=checkbox] + label::before, .widget-checkbox input[type=radio] + label::before,
.widget-checkbox input[type=checkbox] + label::before {
  content: "";
  position: absolute;
  inset: 5px auto auto 0;
  display: block;
  width: 15px;
  height: 15px;
  background: #E3E3E3;
}
.widget-radio input[type=radio]:focus + label::before,
.widget-radio input[type=checkbox]:focus + label::before, .widget-checkbox input[type=radio]:focus + label::before,
.widget-checkbox input[type=checkbox]:focus + label::before {
  outline: 2px solid;
}
.widget-radio input[type=radio] + label::before, .widget-checkbox input[type=radio] + label::before {
  border-radius: 50%;
}
.widget-radio input[type=radio]:checked + label::after, .widget-checkbox input[type=radio]:checked + label::after {
  content: "";
  position: absolute;
  inset: 8px auto auto 3px;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #002d40;
}
.widget-radio input[type=checkbox]:checked + label::after, .widget-checkbox input[type=checkbox]:checked + label::after {
  content: "";
  position: absolute;
  inset: 8px auto auto 2px;
  display: block;
  width: 11px;
  height: 6px;
  border-left: 2px solid #002d40;
  border-bottom: 2px solid #002d40;
  transform: rotateZ(-45deg);
}
.widget select {
  padding-right: 40px;
  background: url("../images/widget-select.svg") right 16px bottom 25px/16px 9px no-repeat;
}
.widget-submit {
  margin-top: 50px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans", sans-serif;
}
.widget-submit button, .widget-submit a {
  font-size: 1em;
  line-height: 1.2;
  letter-spacing: 0.48px;
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.widget-submit button:before, .widget-submit a:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 25%;
  transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
  width: 50%;
}
.widget-submit button:hover:before, .widget-submit a:hover:before {
  left: 0;
  width: 100%;
}

@media screen and (min-width: 1220px) {
  .widget > label {
    font-size: 20px;
    line-height: 24px;
  }
  .widget.empty > label {
    font-size: 24px;
    line-height: 44px;
  }
  .widget input.text,
  .widget input.captcha,
  .widget select,
  .widget textarea {
    height: 85px;
    font-size: 24px;
    line-height: 30px;
  }
  .widget textarea {
    min-height: 250px;
  }
  .widget-radio input[type=radio] + label,
  .widget-radio input[type=checkbox] + label, .widget-checkbox input[type=radio] + label,
  .widget-checkbox input[type=checkbox] + label {
    padding-left: 40px;
  }
  .widget-radio input[type=radio] + label::before,
  .widget-radio input[type=checkbox] + label::before, .widget-checkbox input[type=radio] + label::before,
  .widget-checkbox input[type=checkbox] + label::before {
    inset: 8px auto auto 0;
    width: 25px;
    height: 25px;
  }
  .widget-radio input[type=radio]:checked + label::after, .widget-checkbox input[type=radio]:checked + label::after {
    inset: 14px auto auto 6px;
    width: 13px;
    height: 13px;
  }
  .widget-radio input[type=checkbox]:checked + label::after, .widget-checkbox input[type=checkbox]:checked + label::after {
    inset: 15px auto auto 6px;
    width: 13px;
    height: 8px;
  }
  .widget select {
    background-position: right 16px bottom 32px;
    background-size: 20px 12px;
  }
}
.ce_form {
  margin-bottom: 100px;
}
.ce_form hr {
  width: 100%;
  margin: 0 0 15px;
  border-top: 1px solid #B9B9B9;
  border-bottom: 0;
}
.ce_form fieldset {
  margin: 0;
  padding: 0;
  border: none;
}
.ce_form fieldset > legend {
  font-family: "Work Sans", sans-serif;
}
.ce_form fieldset.radio_container > legend, .ce_form fieldset.checkbox_container > legend {
  margin-top: 0;
}
@media screen and (min-width: 768px) {
  .ce_form fieldset.group-50-50, .ce_form fieldset.group-30-70, .ce_form fieldset.group-70-30, .ce_form fieldset.group-33-33-33 {
    display: grid;
    gap: 0 30px;
  }
  .ce_form fieldset.group-50-50 > .widget.full-width, .ce_form fieldset.group-30-70 > .widget.full-width, .ce_form fieldset.group-70-30 > .widget.full-width, .ce_form fieldset.group-33-33-33 > .widget.full-width {
    grid-column: span 2;
  }
  .ce_form fieldset.group-50-50 {
    grid-template-columns: 1fr 1fr;
  }
  .ce_form fieldset.group-30-70 {
    grid-template-columns: 3fr 7fr;
  }
  .ce_form fieldset.group-70-30 {
    grid-template-columns: 7fr 3fr;
  }
  .ce_form fieldset.group-33-33-33 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .ce_form fieldset.group-33-33-33 > .widget.full-width {
    grid-column: span 3;
  }
}

.section-header__meta a {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.32px;
  transition: opacity 0.1s ease-in-out;
}
.section-header__meta a:hover {
  opacity: 0.6;
}
.section-header__meta a.about > span {
  position: relative;
  padding-left: 45px;
  white-space: nowrap;
}
.section-header__meta a.about > span:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 0;
  display: block;
  width: 30px;
  height: 25px;
  background: url("../../images/nav--about.svg") center center/contain no-repeat;
}
@media screen and (min-width: 768px) {
  .section-header__meta a.about > span {
    padding-left: 35px;
  }
}
@media screen and (min-width: 1220px) {
  .section-header__meta a.about > span {
    padding-left: 45px;
  }
  .section-header__meta a.about > span:before {
    top: -2px;
  }
}
.section-header__meta a.contact > span {
  position: relative;
  padding-left: 45px;
  white-space: nowrap;
}
.section-header__meta a.contact > span:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 0;
  display: block;
  width: 30px;
  height: 25px;
  background: url("../../images/nav--contact.svg") center center/contain no-repeat;
}
@media screen and (min-width: 768px) {
  .section-header__meta a.contact > span {
    padding-left: 35px;
  }
}
@media screen and (min-width: 1220px) {
  .section-header__meta a.contact > span {
    padding-left: 45px;
  }
  .section-header__meta a.contact > span:before {
    top: -2px;
  }
}
.section-header__meta a.cart > span {
  position: relative;
  padding-left: 45px;
  white-space: nowrap;
}
.section-header__meta a.cart > span:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 0;
  display: block;
  width: 30px;
  height: 25px;
  background: url("../../images/nav--cart.svg") center center/contain no-repeat;
}
@media screen and (min-width: 768px) {
  .section-header__meta a.cart > span {
    padding-left: 35px;
  }
}
@media screen and (min-width: 1220px) {
  .section-header__meta a.cart > span {
    padding-left: 45px;
  }
  .section-header__meta a.cart > span:before {
    top: -2px;
  }
}
.section-header__meta a.account > span {
  position: relative;
  padding-left: 40px;
}
.section-header__meta a.account > span:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
  background: url("../../images/nav--account-dark.svg") center center/contain no-repeat;
}
body.magazine--light .section-header__meta a.account > span:before {
  background: url("../../images/nav--account-light.svg") center center/contain no-repeat;
}
@media screen and (min-width: 1220px) {
  .section-header__meta a.account > span:before {
    top: -2px;
  }
}

@media screen and (max-width: 767px) {
  body.has-overlay {
    overflow: hidden;
  }
  .section-header {
    position: relative;
    padding: 20px 27px 25px;
  }
  .section-header__inside {
    display: flex;
    justify-content: space-between;
  }
  .section-header__logo {
    display: block;
    width: 170px;
    height: 34px;
    background: url("../../images/logo-mobile.svg") 0 0/contain no-repeat;
    transition: opacity 0.1s ease-in-out;
  }
  .section-header__logo:hover {
    opacity: 0.6;
  }
  .section-header__toggle {
    position: relative;
    width: 50px;
    height: 35px;
    padding: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  .section-header__toggle span {
    display: block;
    width: 30px;
    height: 1.5px;
    background: #000;
    transition: all 0.1s ease-in-out;
  }
  .section-header__toggle span:before, .section-header__toggle span:after {
    content: "";
    position: absolute;
    left: 10px;
    width: 30px;
    height: 1.5px;
    background: #000;
    transition: all 0.1s ease-in-out;
  }
  .section-header__toggle span:before {
    top: 10px;
  }
  .section-header__toggle span:after {
    bottom: 10px;
  }
  body.has-overlay .section-header__toggle span {
    background: transparent;
  }
  body.has-overlay .section-header__toggle span:before {
    width: 25px;
    left: 12px;
    top: 17px;
    transform: rotateZ(45deg);
  }
  body.has-overlay .section-header__toggle span:after {
    width: 25px;
    left: 12px;
    bottom: 17px;
    transform: rotateZ(-45deg);
  }
  .section-header__overlay {
    opacity: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    position: fixed;
    overflow-y: auto;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 27px;
    background: #fff;
    transition: opacity 0.2s ease-in-out;
    z-index: 999;
  }
  body.has-overlay .section-header__overlay {
    opacity: 1;
    pointer-events: auto;
  }
  .section-header__navigation {
    flex-grow: 1;
    margin-bottom: 20px;
  }
  .section-header__navigation ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .section-header__navigation a {
    position: relative;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.4px;
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 500;
    text-align: left;
  }
  .section-header__navigation a:before {
    content: "";
    position: absolute;
    bottom: -10px;
    height: 2px;
    background: currentColor;
    left: 0;
    transition: width 0.2s ease-in-out;
    width: 0;
  }
  .section-header__navigation a:hover:before {
    width: 50%;
  }
  .section-header__navigation a.active, .section-header__navigation a.trail {
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 500;
    text-align: left;
  }
  .section-header__navigation a.active:before, .section-header__navigation a.trail:before {
    content: "";
    position: absolute;
    bottom: -10px;
    height: 2px;
    background: currentColor;
    left: 0;
    transition: width 0.2s ease-in-out;
    width: 50%;
  }
  .section-header__meta {
    flex-grow: 1;
  }
  .section-header__meta ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .section-header__meta li {
    flex-grow: 1;
    margin: 10px 0;
  }
  .section-header__meta a {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.32px;
  }
  .section-header__meta li.account {
    display: flex;
    align-items: flex-end;
    margin: 50px 0 0;
  }
  .section-header__meta li.account > a {
    flex-grow: 1;
    padding: 20px;
    display: block;
    background: var(--magazine-color);
    color: var(--magazine-text);
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.34px;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .section-header__logo {
    position: absolute;
    top: 20px;
    left: 27px;
    display: block;
    width: 200px;
    height: 50px;
    background: url("../../images/logo-mobile.svg") 0 0/contain no-repeat;
    transition: opacity 0.1s ease-in-out;
  }
  .section-header__logo:hover {
    opacity: 0.6;
  }
  .section-header__toggle {
    display: none;
  }
  .section-header__overlay {
    display: grid;
    grid-template: 100px 50px/250px 1fr;
  }
  .section-header__navigation {
    grid-column: 1/3;
    grid-row: 2/3;
  }
  .section-header__navigation .level_1 {
    display: flex;
    gap: 35px;
    justify-content: space-evenly;
  }
  .section-header__navigation a {
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.5px;
    font-weight: 500;
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 500;
    text-align: left;
  }
  .section-header__navigation a:before {
    content: "";
    position: absolute;
    bottom: -10px;
    height: 2px;
    background: currentColor;
    left: 0;
    transition: width 0.2s ease-in-out;
    width: 0;
  }
  .section-header__navigation a:hover:before {
    width: 50%;
  }
  .section-header__navigation a.active, .section-header__navigation a.trail {
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 500;
    text-align: left;
  }
  .section-header__navigation a.active:before, .section-header__navigation a.trail:before {
    content: "";
    position: absolute;
    bottom: -10px;
    height: 2px;
    background: currentColor;
    left: 0;
    transition: width 0.2s ease-in-out;
    width: 50%;
  }
  .section-header__meta {
    grid-column: 2/3;
    grid-row: 1/2;
    display: flex;
    justify-content: flex-end;
  }
  .section-header__meta ul {
    display: flex;
    width: 100%;
    max-width: 640px;
  }
  .section-header__meta li {
    flex-grow: 1;
  }
  .section-header__meta a {
    display: block;
    padding: 26px 5px 24px;
  }
  .section-header__meta a.account {
    padding-left: 15px;
    padding-right: 15px;
    background: var(--magazine-color);
    color: var(--magazine-text);
    text-align: center;
  }
}
@media screen and (min-width: 1220px) {
  .section-header {
    position: relative;
    max-width: 1800px;
    margin: 0 auto;
    height: 250px;
  }
  .section-header__inside {
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;
    padding: 110px 70px 0;
  }
  .section-header__logo {
    position: static;
    width: 370px;
    height: 70px;
    background: url("../../images/logo-desktop.svg") 0 0/contain no-repeat;
  }
  .section-header__overlay {
    display: block;
  }
  .section-header__navigation {
    margin-top: 25px;
  }
  .section-header__meta {
    position: absolute;
    top: 0;
    right: 0;
  }
  .section-header__meta ul {
    max-width: none;
  }
  .section-header__meta a {
    padding: 23px 15px 21px;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.36px;
  }
  .section-header__meta a.account {
    width: 250px;
    margin-left: 30px;
    background: var(--magazine-color);
    color: var(--magazine-text);
    text-align: center;
  }
}
.section-subheader {
  position: relative;
  overflow-x: hidden;
  z-index: 10;
}
.section-subheader__breadcrumb {
  padding: 15px 27px 20px;
  color: #818484;
  font-size: 13px;
  line-height: 15px;
}
.section-subheader__breadcrumb li {
  display: inline;
}
.section-subheader__breadcrumb li::after {
  content: "";
  position: relative;
  top: 2px;
  display: inline-block;
  width: 6px;
  height: 12px;
  margin: 0 4px;
  background: url("../../images/breadcrumb.svg") 0 0/contain no-repeat;
}
.section-subheader__breadcrumb li:last-child::after {
  content: none;
}
.section-subheader__breadcrumb a {
  transition: color 0.1s ease-in-out;
}
.section-subheader__breadcrumb a:hover {
  color: #002d40;
}
.section-subheader__breadcrumb svg {
  position: relative;
  top: 1px;
  width: 12px;
  height: 13px;
  margin-right: 3px;
}

@media screen and (min-width: 768px) {
  .section-subheader__breadcrumb {
    padding: 25px 27px 26px;
    font-size: 16px;
    line-height: 19px;
  }
  .section-subheader__breadcrumb li::after {
    width: 7px;
    height: 14px;
  }
  .section-subheader__breadcrumb svg {
    top: 2px;
    width: 15px;
    height: 16px;
  }
}
@media screen and (min-width: 1220px) {
  .section-subheader {
    max-width: 1440px;
    margin: 0 auto;
  }
  .section-subheader__breadcrumb {
    padding-left: 70px;
    padding-right: 70px;
  }
}
.section-main {
  font-size: 17px;
  line-height: 1.5;
}
.section-main__inside {
  padding: 0 27px;
}
.section-main h1, .section-main h2, .section-main h3, .section-main h4, .section-main h5, .section-main h6 {
  font-weight: 500;
  letter-spacing: 0.4px;
}
.section-main h1, .section-main .h1 {
  font-size: 39px;
  line-height: 1.2;
}
.section-main h1 .serif, .section-main .h1 .serif {
  font-weight: 400;
  font-family: "freight-text-pro", serif;
  font-size: 1.1em;
}
.section-main h2, .section-main .h2 {
  margin-top: 55px;
  margin-bottom: 45px;
  font-size: 20px;
  line-height: 1.25;
}
.section-main h2 + h1, .section-main .h2 + h1 {
  margin-top: -35px;
}
.section-main h3, .section-main .h3 {
  margin-top: 55px;
  font-size: 18px;
  line-height: 1.6;
}
.section-main h4, .section-main .h4, .section-main .ce_form fieldset > legend, .ce_form .section-main fieldset > legend {
  margin-top: 3em;
  margin-bottom: 1em;
}
.section-main h4:first-child, .section-main .h4:first-child, .section-main .ce_form fieldset > legend:first-child, .ce_form .section-main fieldset > legend:first-child {
  margin-top: 1em;
}
.section-main hr {
  display: block;
  width: 100%;
  height: 2px;
  margin: 50px 0;
  background: #B9B9B9;
  border: none;
  opacity: 0.5;
}
.section-main mark {
  background: #D3F1FE;
}
.section-main .mod_article.background {
  position: relative;
  margin: 55px 0;
  padding: 0.1px 0 55px;
}
.section-main .mod_article.background:before {
  content: "";
  position: absolute;
  inset: 0 -27px;
  background: #FAF8F8;
  z-index: -1;
}
.section-main .ce_text {
  font-family: "freight-text-pro", serif;
}
.section-main .ce_text h1, .section-main .ce_text h2, .section-main .ce_text h3, .section-main .ce_text h4, .section-main .ce_text h5, .section-main .ce_text h6 {
  font-family: "Work Sans", sans-serif;
}
.section-main .ce_text h2 {
  margin-bottom: 15px;
}
.section-main .ce_text a {
  text-decoration: underline;
}
.section-main .mod_article:first-child > .ce_text:first-child > h2 {
  margin-top: 15px;
}
.section-main .pagination {
  margin: 100px 0;
}
.section-main .pagination p {
  display: none;
}
.section-main .pagination ul {
  display: flex;
  justify-content: center;
}
.section-main .pagination li.first, .section-main .pagination li.last {
  display: none;
}
.section-main .pagination a,
.section-main .pagination strong {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
  display: block;
  margin: 0 5px;
  padding: 0 12px;
  font-weight: 400;
  text-align: center;
  line-height: 1;
}
.section-main .pagination a:before,
.section-main .pagination strong:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 25%;
  transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
  width: 0;
}
.section-main .pagination a:hover:before,
.section-main .pagination strong:hover:before {
  left: 0;
  width: 100%;
}
.section-main .pagination a.next, .section-main .pagination a.previous,
.section-main .pagination strong.next,
.section-main .pagination strong.previous {
  width: 80px;
  padding: 0 20px;
  background: url("../../images/next.svg") 50% 50% no-repeat;
  text-indent: -999em;
  transition: all 0.3s ease;
}
.section-main .pagination a.next:before, .section-main .pagination a.previous:before,
.section-main .pagination strong.next:before,
.section-main .pagination strong.previous:before {
  content: none;
}
.section-main .pagination a.next:hover, .section-main .pagination a.previous:hover,
.section-main .pagination strong.next:hover,
.section-main .pagination strong.previous:hover {
  background-position: 80% 50%;
  opacity: 0.5;
}
.section-main .pagination a.previous,
.section-main .pagination strong.previous {
  transform: rotateZ(180deg);
}
.section-main .pagination strong {
  font-weight: 500;
}
.section-main .pagination strong:before {
  left: 0;
  width: 100%;
}
.section-main .desktop-only {
  display: none;
}

@media screen and (min-width: 768px) {
  .section-main .content-grid-row__inside > .ce_text:first-child > h3 {
    margin-top: 0;
  }
  .section-main .mobile-only {
    display: none;
  }
  .section-main .desktop-only {
    display: initial;
  }
}
@media screen and (min-width: 1220px) {
  .section-main {
    font-size: 24px;
    line-height: 1.8;
  }
  .section-main__inside {
    margin: 0 auto;
    padding: 0;
    max-width: 1440px;
  }
  .section-main__inside .mod_article > * {
    margin-left: 70px;
    margin-right: 70px;
  }
  .section-main__inside .mod_article > *.wide {
    margin-left: 0;
    margin-right: 0;
  }
  .section-main h2, .section-main h3, .section-main h4, .section-main h5, .section-main h6 {
    letter-spacing: 0.63px;
  }
  .section-main h1, .section-main .h1 {
    font-size: 80px;
    line-height: 1.2;
  }
  .section-main h2, .section-main .h2 {
    margin-top: 90px;
    margin-bottom: 55px;
    font-size: 33px;
    line-height: 1.2;
  }
  .section-main h3, .section-main .h3 {
    font-size: 30px;
    line-height: 1.5;
  }
  .section-main .centered {
    margin-left: 130px;
    margin-right: 130px;
  }
  .section-main .centered > h2 {
    margin-left: -60px;
    margin-right: -60px;
  }
  .section-main .centered .centered {
    margin-left: 60px;
    margin-right: 60px;
  }
  .section-main .ce_headline.large h1 {
    font-size: 88px;
    line-height: 1.2;
  }
  .section-main .ce_text.small {
    font-size: 20px;
    line-height: 1.5;
  }
  .section-main .mod_article:first-child > .ce_text:first-child > h2 {
    margin-top: 55px;
  }
  .section-main .mod_article.background {
    margin: 100px 0;
    padding: 45px 0 100px;
  }
  .section-main .mod_article.background:before {
    inset: 0;
  }
}
@media screen and (min-width: 1440px) {
  .section-main .mod_article.background:before {
    inset: 0 calc((-100vw + 1440px) / 2);
  }
}
.section-social__inside {
  padding: 60px 0 40px;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
}
.section-social__title {
  margin: 0 0 1em 0;
}
.section-social ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.section-social__icon {
  display: block;
  width: 25px;
  height: 25px;
  background: url("../../images/social.svg") 0 0/25px 100px no-repeat;
  text-indent: -999em;
  transition: opacity 0.1s ease-in-out;
}
.section-social__icon:hover {
  opacity: 0.6;
}
.section-social__icon--facebook {
  background-position: 0 0;
}
.section-social__icon--twitter {
  background-position: 0 -25px;
}
.section-social__icon--linkedin {
  background-position: 0 -50px;
}
.section-social__icon--email {
  background-position: 0 -75px;
}

@media screen and (min-width: 768px) {
  .section-social__inside {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    font-size: 18px;
    line-height: 21px;
  }
  .section-social__title {
    margin: 0 15px 0 0;
  }
}
.section-prefooter {
  color: #EBEBEB;
  font-size: 13px;
  line-height: 19px;
  background: #6A7780;
}
.section-prefooter__inside {
  position: relative;
  padding: 50px 0;
}
.section-prefooter__top {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 37px;
  height: 30px;
  text-indent: -999em;
  background: #002d40 url("../../images/link-top.svg") center center no-repeat;
}
.section-prefooter__address {
  padding: 0 20px;
}
.section-prefooter__address h1 {
  margin: 0;
  color: #F9F9F9;
  font-size: 18px;
  font-weight: 600;
}
.section-prefooter__address a:hover {
  text-decoration: underline;
}
.section-prefooter__social {
  display: inline-block;
  width: 1.4em;
  height: 1.2em;
  margin-right: 6px;
  text-indent: -999em;
  background: #fff;
  transition: opacity 0.1s ease-in-out;
}
.section-prefooter__social:hover {
  opacity: 0.6;
}
.section-prefooter__social--twitter {
  background: url("../../images/twitter.svg") 50% 50%/contain no-repeat;
}
.section-prefooter__social--instagram {
  background: url("../../images/instagram.svg") 50% 50%/contain no-repeat;
}
.section-prefooter__about, .section-prefooter__quicklinks {
  margin-top: 45px;
}
.section-prefooter__about h2, .section-prefooter__quicklinks h2 {
  margin: 0;
  padding: 0 20px 11px;
  font-size: 14px;
  text-transform: uppercase;
}
.section-prefooter__about ul, .section-prefooter__quicklinks ul {
  border-top: 1px solid #EBEBEB;
}
.section-prefooter__about li, .section-prefooter__quicklinks li {
  border-bottom: 1px solid #EBEBEB;
}
.section-prefooter__about a, .section-prefooter__quicklinks a {
  position: relative;
  display: block;
  padding: 12px 60px 11px 20px;
  transition: padding-left 0.3s linear, opacity 0.1s ease-in-out;
}
.section-prefooter__about a:after, .section-prefooter__quicklinks a:after {
  position: absolute;
  right: 40px;
  top: 10px;
  width: 25px;
  height: 25px;
  opacity: 0;
  content: "";
  transition: all 0.3s linear;
}
.section-prefooter__about a:hover, .section-prefooter__quicklinks a:hover {
  opacity: 0.8;
}
.section-prefooter__about a:hover:after, .section-prefooter__quicklinks a:hover:after {
  right: 20px;
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .section-prefooter {
    overflow: hidden;
    font-size: 17px;
    line-height: 23px;
  }
  .section-prefooter__inside {
    padding: 75px 0 0;
  }
  .section-prefooter__address {
    float: left;
    width: 50%;
    padding: 0 30px;
  }
  .section-prefooter__address h1 {
    font-size: 20px;
  }
  .section-prefooter__about, .section-prefooter__quicklinks {
    width: 50%;
    margin: 0 0 45px 50%;
    padding: 0 30px;
  }
  .section-prefooter__about h2, .section-prefooter__quicklinks h2 {
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
  }
  .section-prefooter__about a, .section-prefooter__quicklinks a {
    padding-left: 0;
    padding-right: 0;
  }
  .section-prefooter__about a:after, .section-prefooter__quicklinks a:after {
    top: 13px;
  }
}
@media screen and (min-width: 1220px) {
  .section-prefooter__inside {
    max-width: 1260px;
    margin: 0 auto;
    padding-bottom: 80px;
  }
  .section-prefooter__top {
    display: none;
  }
  .section-prefooter__address {
    width: 40%;
  }
  .section-prefooter__about, .section-prefooter__quicklinks {
    float: left;
    width: 28%;
    margin-left: 0;
    margin-bottom: 0;
  }
}
.section-footer {
  padding: 27px 0 24px;
  color: #EBEBEB;
  font-size: 13px;
  line-height: 19px;
  background: #6A7780;
}
.section-footer__inside {
  position: relative;
  padding: 0 20px;
}
.section-footer__navigation li {
  display: inline;
  padding-right: 20px;
}
.section-footer__navigation a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .section-footer {
    padding: 40px 0 37px;
    font-size: 15px;
  }
  .section-footer__inside {
    padding: 0 30px;
  }
}
@media screen and (min-width: 768px) {
  .section-footer__inside {
    max-width: 1260px;
    margin: 0 auto;
  }
}
.handorgel {
  display: block;
  width: 100%;
  border: 1px solid #eee;
  border-top: none;
}

.handorgel__header {
  display: block;
  margin: 0;
}

.handorgel__header--open .handorgel__header__button {
  background-color: #eee;
}

.handorgel__header--focus .handorgel__header__button {
  background-color: #dfdfdf;
  outline: none;
}

.handorgel__header__button {
  display: block;
  width: 100%;
  padding: 20px 24px;
  margin: 0;
  border: none;
  border-top: 1px solid #eee;
  background-color: #fff;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  text-align: left;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.handorgel__header__button::-moz-focus-inner {
  border: 0;
}

.handorgel__content {
  display: none;
  overflow: hidden;
  height: 0;
  border-top: 1px solid #eee;
  background-color: #fff;
  -webkit-transition: height 0.1s ease 0.1s;
  transition: height 0.1s ease 0.1s;
}

.handorgel__content--open {
  display: block;
  -webkit-transition: height 0.2s ease;
  transition: height 0.2s ease;
}

.handorgel__content--opened {
  overflow: visible;
}

.handorgel__content__inner {
  padding: 20px 24px;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}

.handorgel__content--opened .handorgel__content__inner {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

@media screen and (min-width: 1220px) {
  .ce_accordion {
    font-size: 20px;
    line-height: 30px;
  }
}
.handorgel {
  width: auto;
  margin: 0;
  border-collapse: collapse;
  border: none;
  border-top: 1px solid #002d40;
  border-bottom: 1px solid #002d40;
}
.handorgel + .handorgel {
  border-top: none;
}
.handorgel .toggler {
  position: relative;
  margin: 3px 0 0;
  transition: 0.1s opacity ease-in-out;
}
.handorgel .toggler::before {
  position: absolute;
  top: 17px;
  right: 25px;
  width: 2px;
  height: 12px;
  background: #002d40;
  content: "";
  transform: rotateX(0deg);
  transition: transform 0.1s linear;
  pointer-events: none;
}
.handorgel .toggler::after {
  position: absolute;
  top: 22px;
  right: 20px;
  width: 12px;
  height: 2px;
  background: #002d40;
  content: "";
  pointer-events: none;
}
.handorgel .toggler:hover::before, .handorgel .toggler:hover::after, .handorgel .toggler:hover {
  opacity: 0.6;
}
.handorgel .toggler button {
  overflow: hidden;
  padding-right: 40px;
}
.handorgel .toggler.open:before {
  transform: rotateX(90deg);
}
.handorgel button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  font-size: 15px;
  line-height: 26px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: background 0.3s;
}
.handorgel .accordion {
  padding: 0 20px;
  border: none;
  border-top: 1px solid #002d40;
}
.handorgel .accordion > div {
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .handorgel {
    margin: 0;
  }
  .handorgel button {
    font-size: 16px;
  }
}
@media screen and (min-width: 1220px) {
  .handorgel .toggler::before {
    top: 22px;
    right: 25px;
  }
  .handorgel .toggler::after {
    top: 27px;
    right: 20px;
  }
  .handorgel button {
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.content-ads {
  margin-top: 45px;
}
.content-ads__slider .tns-inner {
  background: #fff;
}
.content-ads__image {
  text-align: center;
}
.content-ads__content {
  padding: 0 27px 50px;
}
.content-ads__content h1 {
  margin: 30px 0 5px;
  font-size: 30px;
  line-height: 38px;
}
.content-ads__table {
  font-size: 16px;
  line-height: 1.25;
}
.content-ads__col--title {
  padding-top: 12px;
  font-weight: 500;
}
.content-ads__col--body {
  padding-bottom: 12px;
  font-family: "freight-text-pro", serif;
  border-bottom: 1px solid #002d40;
}
.content-ads__list {
  display: none;
}
.content-ads + .ce_download {
  margin: 45px 0 55px;
  text-align: center;
}
.content-ads + .ce_download a {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.content-ads + .ce_download a:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 25%;
  transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
  width: 50%;
}
.content-ads + .ce_download a:hover:before {
  left: 0;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .content-ads {
    overflow: hidden;
    padding: 0 70px;
  }
  .content-ads__item {
    display: flex;
  }
  .content-ads__image {
    width: 440px;
  }
  .content-ads__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 40px 8%;
  }
  .content-ads__row {
    display: flex;
    justify-content: flex-start;
  }
  .content-ads__col {
    padding: 12px;
    border-bottom: 1px solid #002d40;
  }
  .content-ads__col--title {
    width: 40%;
    padding-top: 12px;
    font-weight: 500;
  }
  .content-ads__col--body {
    width: 60%;
    padding-bottom: 12px;
    font-family: "freight-text-pro", serif;
  }
  .content-ads__list {
    display: block;
    margin: 100px 60px 0;
  }
  .content-ads__list table {
    width: 100%;
  }
  .content-ads__list th, .content-ads__list td {
    padding: 8px 12px;
    border-bottom: 1px solid #002d40;
  }
  .content-ads__list th:first-child, .content-ads__list td:first-child {
    width: 30%;
  }
  .content-ads__list th:last-child, .content-ads__list td:last-child {
    width: 20%;
  }
  .content-ads__list td {
    font-family: "freight-text-pro", serif;
  }
}
@media screen and (min-width: 1220px) {
  .content-ads {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .content-ads__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 40px 8%;
  }
  .content-ads__content h1 {
    font-size: 70px;
    line-height: 95px;
    margin: 0 0 20px;
  }
  .content-ads__table, .content-ads__list th, .content-ads td {
    font-size: 20px;
    line-height: 1.5;
  }
  .content-ads + .ce_download {
    margin: 80px 0;
  }
}
.content-grid-row--background > .content-grid-row__inside {
  padding: 35px 20px;
  background: #FAF8F8;
}
.content-grid-row--background > .content-grid-row__inside > :first-child,
.content-grid-row--background > .content-grid-row__inside > :first-child > :first-child {
  margin-top: 0;
}
.content-grid-row--background + .content-grid-row--background {
  margin-top: 25px;
}
.content-grid-row__inside > .mod_login:last-child .widget-submit,
.content-grid-row__inside > .mod_registration:last-child .widget-submit {
  margin-bottom: 0 !important;
}
.ce_text + .content-grid-row {
  margin-top: 40px;
}

@media screen and (min-width: 768px) {
  .content-grid-row--md3 > .content-grid-row__inside {
    display: flex;
    flex-wrap: wrap;
    margin-left: -25px;
    margin-right: -25px;
  }
  .content-grid-row--md3 > .content-grid-row__inside > * {
    padding-left: 25px;
    padding-right: 25px;
  }
  .content-grid-row--md3 > .content-grid-row__inside > * {
    padding-left: 25px;
    padding-right: 25px;
    width: 25%;
  }
  .content-grid-row--offset-md3 > .content-grid-row__inside > * {
    margin-left: 25% !important;
  }
  .content-grid-row--md4 > .content-grid-row__inside {
    display: flex;
    flex-wrap: wrap;
    margin-left: -25px;
    margin-right: -25px;
  }
  .content-grid-row--md4 > .content-grid-row__inside > * {
    padding-left: 25px;
    padding-right: 25px;
  }
  .content-grid-row--md4 > .content-grid-row__inside > * {
    padding-left: 25px;
    padding-right: 25px;
    width: 33.3333333333%;
  }
  .content-grid-row--offset-md4 > .content-grid-row__inside > * {
    margin-left: 33.3333333333% !important;
  }
  .content-grid-row--md6 > .content-grid-row__inside {
    display: flex;
    flex-wrap: wrap;
    margin-left: -25px;
    margin-right: -25px;
  }
  .content-grid-row--md6 > .content-grid-row__inside > * {
    padding-left: 25px;
    padding-right: 25px;
  }
  .content-grid-row--md6 > .content-grid-row__inside > * {
    padding-left: 25px;
    padding-right: 25px;
    width: 50%;
  }
  .content-grid-row--offset-md6 > .content-grid-row__inside > * {
    margin-left: 50% !important;
  }
  .content-grid-row--align-start > .content-grid-row__inside {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    align-items: start;
  }
  .content-grid-row--align-start > .content-grid-row__inside :first-child {
    margin-top: 0;
  }
  .content-grid-row--align-center > .content-grid-row__inside {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    align-items: center;
  }
  .content-grid-row--align-center > .content-grid-row__inside :first-child {
    margin-top: 0;
  }
  .content-grid-row--align-end > .content-grid-row__inside {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    align-items: end;
  }
  .content-grid-row--align-end > .content-grid-row__inside :first-child {
    margin-top: 0;
  }
  .content-grid-row--justify-start > .content-grid-row__inside {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    justify-content: start;
  }
  .content-grid-row--justify-start > .content-grid-row__inside :first-child {
    margin-top: 0;
  }
  .content-grid-row--justify-center > .content-grid-row__inside {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    justify-content: center;
  }
  .content-grid-row--justify-center > .content-grid-row__inside :first-child {
    margin-top: 0;
  }
  .content-grid-row--justify-end > .content-grid-row__inside {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    justify-content: end;
  }
  .content-grid-row--justify-end > .content-grid-row__inside :first-child {
    margin-top: 0;
  }
  .content-grid-row--background > .content-grid-row__inside {
    padding: 50px;
  }
  .content-grid-row--background + .content-grid-row--background {
    margin-top: 0;
  }
}
@media screen and (min-width: 1220px) {
  .content-grid-row--lg3 > .content-grid-row__inside {
    display: flex;
    flex-wrap: wrap;
    margin-left: -25px;
    margin-right: -25px;
  }
  .content-grid-row--lg3 > .content-grid-row__inside > * {
    padding-left: 25px;
    padding-right: 25px;
  }
  .content-grid-row--lg3 > .content-grid-row__inside > * {
    padding-left: 25px;
    padding-right: 25px;
    width: 25%;
  }
  .content-grid-row--offset-lg3 > * {
    margin-left: 25% !important;
  }
  .content-grid-row--lg4 > .content-grid-row__inside {
    display: flex;
    flex-wrap: wrap;
    margin-left: -25px;
    margin-right: -25px;
  }
  .content-grid-row--lg4 > .content-grid-row__inside > * {
    padding-left: 25px;
    padding-right: 25px;
  }
  .content-grid-row--lg4 > .content-grid-row__inside > * {
    padding-left: 25px;
    padding-right: 25px;
    width: 33.3333333333%;
  }
  .content-grid-row--offset-lg4 > * {
    margin-left: 33.3333333333% !important;
  }
  .content-grid-row--lg6 > .content-grid-row__inside {
    display: flex;
    flex-wrap: wrap;
    margin-left: -25px;
    margin-right: -25px;
  }
  .content-grid-row--lg6 > .content-grid-row__inside > * {
    padding-left: 25px;
    padding-right: 25px;
  }
  .content-grid-row--lg6 > .content-grid-row__inside > * {
    padding-left: 25px;
    padding-right: 25px;
    width: 50%;
  }
  .content-grid-row--offset-lg6 > * {
    margin-left: 50% !important;
  }
  .ce_text + .content-grid-row {
    margin-top: 70px;
  }
}
.home-intro {
  margin-top: 40px;
  margin-bottom: 50px;
}
.home-intro__overlap {
  display: none;
}
.home-intro__title {
  margin: 0;
  font-size: 39px;
  line-height: 46px;
}
.home-intro__description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  font: 500 1.1em "freight-text-pro", serif;
}
.home-intro__link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin: 20px 0 0;
}
.home-intro__link a, .home-intro__link button {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.home-intro__link a:before, .home-intro__link button:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.home-intro__link a:hover:before, .home-intro__link button:hover:before {
  width: 100%;
}
.home-intro__image {
  margin: 50px -27px;
  padding: 40px 0 40px 44px;
  background: var(--magazine-color);
}
.home-intro__image figure {
  display: inline-block;
  transform: matrix(0.99, -0.1, 0.1, 0.99, 0, 0);
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.3019607843);
}
.home-intro__image img {
  width: 200px;
}

@media screen and (min-width: 768px) {
  .home-intro {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 120px;
  }
  .home-intro__content, .home-intro__overlap {
    display: block;
    position: relative;
    overflow: hidden;
    width: 25%;
    margin: 0;
  }
  .home-intro__content-inner, .home-intro__overlap-inner {
    width: 200%;
  }
  .home-intro__overlap {
    color: var(--magazine-text);
  }
  .home-intro__overlap-inner {
    margin-left: -100%;
  }
  .home-intro__image {
    position: relative;
    width: 50%;
    margin: 0;
    padding: 60px 0;
    text-align: center;
  }
  .home-intro__image figure {
    width: 70%;
  }
  .home-intro__image img {
    width: 100%;
  }
  .home-intro__image:before {
    content: "";
    position: absolute;
    left: -50%;
    right: -27px;
    top: 0;
    bottom: 0;
    background: var(--magazine-color);
    z-index: -1;
  }
}
@media screen and (min-width: 1220px) {
  .home-intro__title {
    font-size: 96px !important;
    line-height: 110px !important;
  }
  .home-intro__image {
    padding: 90px 0 130px;
  }
  .home-intro__image:before {
    right: -70px;
  }
}
@media screen and (min-width: 1440px) {
  .home-intro__image figure {
    width: 100%;
  }
  .home-intro__image:before {
    left: -325px;
    right: calc(-50vw + 650px);
  }
}
.home-articles {
  margin: -20px -20px 0;
}
.home-articles__article {
  display: block;
  padding: 20px;
}
.home-articles__article--video {
  position: relative;
}
.home-articles__article--video svg {
  position: absolute;
  inset: 235px auto auto 35px;
  width: 50px;
  height: 50px;
}
.home-articles__article figure {
  height: 280px;
}
.home-articles__text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  font: 500 17px/27px "freight-text-pro", serif;
}

@media screen and (min-width: 768px) {
  .home-articles {
    margin: -20px 0 0;
  }
  .home-articles .tns-outer {
    margin: 0 -20px;
  }
}
.ce_hyperlink,
.ce_download {
  font-family: "freight-text-pro", serif;
  line-height: 1.2;
}
.ce_hyperlink a,
.ce_download a {
  font-family: "Work Sans", sans-serif;
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.ce_hyperlink a:before,
.ce_download a:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.ce_hyperlink a:hover:before,
.ce_download a:hover:before {
  width: 100%;
}
.ce_hyperlink.center, hr + .ce_hyperlink,
.ce_download.center,
hr + .ce_download {
  text-align: center;
}
.ce_hyperlink.center a, hr + .ce_hyperlink a,
.ce_download.center a,
hr + .ce_download a {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.ce_hyperlink.center a:before, hr + .ce_hyperlink a:before,
.ce_download.center a:before,
hr + .ce_download a:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 25%;
  transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
  width: 50%;
}
.ce_hyperlink.center a:hover:before, hr + .ce_hyperlink a:hover:before,
.ce_download.center a:hover:before,
hr + .ce_download a:hover:before {
  left: 0;
  width: 100%;
}
.ce_hyperlink + .ce_hyperlink,
.ce_hyperlink + .ce_download,
.ce_download + .ce_hyperlink,
.ce_download + .ce_download {
  margin-top: 0.5em;
}

.ce_hyperlink_headline svg {
  width: 22px;
  margin-left: 8px;
  color: var(--magazine-color);
}

@media screen and (min-width: 768px) {
  .ce_hyperlink_headline svg {
    width: 35px;
  }
}
.content-linkbox {
  background: #FAF8F8;
}
.content-linkbox--magazine-color {
  color: var(--magazine-text);
  background: var(--magazine-color);
}
.content-linkbox__image img {
  width: 100%;
}
.content-linkbox__content {
  padding: 35px 20px 50px;
}
.content-linkbox__headline {
  margin: 0;
  font-weight: 400 !important;
}
.content-linkbox__text {
  font-family: "freight-text-pro", serif;
  font-size: 1.1em;
}
.content-linkbox__link a {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.content-linkbox__link a:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.content-linkbox__link a:hover:before {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .content-linkbox {
    display: flex;
    justify-content: space-between;
    margin: 0 !important;
  }
  .content-linkbox__image {
    width: 35%;
    padding: 30px 0 30px 30px;
  }
  .content-linkbox__image img {
    box-shadow: 20px 20px var(--magazine-color);
  }
  .content-linkbox__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .content-linkbox--borderless .content-linkbox__image {
    padding: 0;
  }
  .content-linkbox--borderless .content-linkbox__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: none;
  }
  .content-linkbox--right {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 1220px) {
  .content-linkbox__image {
    padding: 70px 0 70px 70px;
  }
  .content-linkbox__content {
    padding: 70px;
  }
}
.content-quote {
  position: relative;
  padding: 100px 10%;
}
.content-quote__text {
  font-family: "freight-text-pro", serif;
  font-size: 43px;
  line-height: 60px;
}
.content-quote__name {
  font-size: 17px;
  line-height: 23px;
  font-weight: 500;
}
.content-quote__source {
  font-weight: 400;
}

.content-search {
  margin: 50px -27px;
  background: #DFE2E3;
}
.content-search:first-child {
  margin-top: 0 !important;
}
body.layout--home .content-search {
  color: var(--magazine-text);
  background: var(--magazine-color);
}
.content-search form {
  position: relative;
}
.content-search__image img {
  width: 100%;
}
.content-search__content {
  padding: 55px 30px 75px;
}
.content-search__headline {
  margin: 0 0 23px !important;
}
.content-search__text {
  margin-bottom: 40px;
  font: 500 19px/28px "freight-text-pro", serif;
}
.content-search__input {
  height: 60px;
  width: 100%;
  padding: 0 50px 0 25px;
  color: #002d40;
  border: 3px solid #ECF0F2;
  border-radius: 100px;
}
.content-search__input::placeholder {
  color: #002d40;
  opacity: 1;
}
.content-search__input:focus {
  outline: 5px solid #ECF0F2;
}
body.layout--home .content-search__input {
  border-color: color-mix(in srgb, var(--magazine-color), white);
}
body.layout--home .content-search__input:focus {
  outline-color: color-mix(in srgb, var(--magazine-color), white);
}
.content-search__button {
  position: absolute;
  top: 20px;
  right: 22px;
  width: 20px;
  height: 20px;
  border: none;
  background: url("../../images/search.svg") 0 0/contain no-repeat;
  text-indent: -999em;
  cursor: pointer;
}
.content-search__link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin: 20px 0 0;
}
.content-search__link a, .content-search__link button {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.content-search__link a:before, .content-search__link button:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.content-search__link a:hover:before, .content-search__link button:hover:before {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .content-search {
    margin: 80px 0 0;
    position: relative;
  }
  .content-search:before {
    content: "";
    position: absolute;
    left: -27px;
    right: -27px;
    top: 0;
    bottom: 0;
    background: #DFE2E3;
    z-index: -1;
  }
}
@media screen and (min-width: 768px) and (min-width: 1220px) {
  .content-search:before {
    left: -70px;
    right: -70px;
  }
}
@media screen and (min-width: 768px) and (min-width: 1440px) {
  .content-search:before {
    left: calc(-50vw + 650px);
    right: calc(-50vw + 650px);
  }
}
@media screen and (min-width: 768px) {
  body.layout--home .content-search {
    position: relative;
  }
  body.layout--home .content-search:before {
    content: "";
    position: absolute;
    left: -27px;
    right: -27px;
    top: 0;
    bottom: 0;
    background: var(--magazine-color);
    z-index: -1;
  }
}
@media screen and (min-width: 768px) and (min-width: 1220px) {
  body.layout--home .content-search:before {
    left: -70px;
    right: -70px;
  }
}
@media screen and (min-width: 768px) and (min-width: 1440px) {
  body.layout--home .content-search:before {
    left: calc(-50vw + 650px);
    right: calc(-50vw + 650px);
  }
}
@media screen and (min-width: 768px) {
  .content-search__inside {
    padding-left: 100px;
    padding-right: 100px;
  }
  .content-search--image .content-search__inside {
    display: flex;
    align-items: center;
  }
  .content-search__content {
    padding: 100px 0;
  }
  .content-search__image {
    max-height: 789px;
    margin-right: 50px;
  }
  .content-search__input {
    padding-right: 90px;
  }
  .content-search__button {
    top: 25px;
    right: 42px;
    width: 30px;
    height: 30px;
  }
}
@media screen and (min-width: 1220px) {
  .content-search__inside {
    padding-left: 10%;
    padding-right: 10%;
  }
  .content-search__image {
    margin-right: 110px;
  }
  .content-search__text {
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
  }
  .content-search__input {
    height: 80px;
  }
}
@media screen and (min-width: 1440px) {
  .content-search--image .content-search__inside {
    position: relative;
    left: calc(-50vw + 650px);
    width: 100vw;
    padding-left: 0;
    padding-right: 15%;
  }
}
@media screen and (min-width: 1800px) {
  .content-search--image .content-search__inside {
    left: -250px;
    width: 1800px;
  }
}

.module-search {
  padding-top: 100px;
  font-size: 20px;
  line-height: 30px;
}
.module-search__summary {
  margin: 0 0 80px;
  font-size: 30px;
  line-height: 45px;
}
.module-search__summary strong {
  font-weight: 500;
}
.module-search__items {
  border-top: 1px solid #B9B9B9;
}
.module-search__item {
  padding: 40px 0;
  border-bottom: 1px solid #B9B9B9;
}
.module-search__header {
  margin-bottom: 40px;
}
.module-search .image_container {
  margin-bottom: 40px;
}
.module-search .image_container img {
  width: 100%;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.3019607843);
}
.module-search .add-to-cart {
  float: right;
  margin-left: 20px;
}
.module-search .add-to-cart__button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.1s ease;
}
.module-search .add-to-cart__button:hover {
  color: var(--magazine-color);
}
.module-search .add-to-cart__button svg {
  width: 45px;
  height: 40px;
}
.module-search__headline {
  margin: 0 0 15px;
  font: 20px/24px "freight-text-pro", serif !important;
}
.module-search__link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 10px 0 0;
}
.module-search__link a, .module-search__link button {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  line-height: 18px;
}
.module-search__link a:before, .module-search__link button:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.module-search__link a:hover:before, .module-search__link button:hover:before {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .module-search__link a, .module-search__link button {
    font-size: 20px;
    line-height: 30px;
  }
}
.module-search__article-title {
  margin: 0 !important;
  font-size: 24px !important;
  line-height: 30px !important;
}
.module-search__article-author {
  margin: 0;
  color: #818484;
  font-size: 17px;
  line-height: 23px;
}
.module-search__article-text {
  margin: 25px 0 0;
  font-family: "freight-text-pro", serif;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.module-search__article-preview {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.module-search__article-preview:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.module-search__article-preview:hover:before {
  width: 100%;
}
.module-search__article + .module-search__article {
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .module-search__item {
    display: flex;
    padding: 80px 0;
  }
  .module-search__header {
    width: 35%;
    padding: 0 70px;
    flex-shrink: 0;
  }
  .module-search__article + .module-search__article {
    margin-top: 60px;
  }
}
.tns-outer {
  position: relative;
}
.tns-outer .tns-ovh {
  padding-bottom: 30px;
}
.tns-outer .tns-nav {
  position: absolute;
  z-index: 100;
  inset: auto 0 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.tns-outer .tns-nav button {
  width: 12px;
  height: 12px;
  background: #E3E3E3;
  border: none;
  border-radius: 50%;
}
.tns-outer .tns-nav button.tns-nav-active {
  background: var(--magazine-color);
}
.tns-outer .tns-controls {
  display: none;
}

@media screen and (min-width: 768px) {
  .tns-outer .tns-ovh {
    padding-bottom: 50px;
  }
  .tns-outer .tns-controls {
    display: flex;
    justify-content: space-between;
    position: absolute;
    inset: calc(50% - 65px) -65px 0;
    pointer-events: none;
  }
  .tns-outer .tns-controls button {
    width: 30px;
    height: 55px;
    border: none;
    background: url("../../images/slider-control.svg") 0 0 no-repeat;
    text-indent: -999em;
    transition: opacity 0.1s ease-in-out;
    pointer-events: auto;
  }
  .tns-outer .tns-controls button:hover {
    opacity: 0.6;
  }
  .tns-outer .tns-controls button:disabled {
    opacity: 0.2;
  }
  .tns-outer .tns-controls button:last-child {
    transform: rotateY(180deg);
  }
}
@media screen and (min-width: 1220px) {
  .tns-outer .tns-ovh {
    padding-bottom: 80px;
  }
  .tns-outer .tns-nav button {
    width: 22px;
    height: 22px;
  }
}
.messages {
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  padding: 25px;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
}
.messages--open {
  opacity: 1;
  pointer-events: auto;
}
.messages__inner {
  position: relative;
  padding: 100px 40px 50px 80px;
  background: #fff;
}
.messages__close {
  position: absolute;
  inset: 30px 30px auto auto;
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  cursor: pointer;
}
.messages__close svg {
  width: 100%;
  height: 100%;
}
.messages__actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.messages__actions a,
.messages__actions button {
  font-size: 20px;
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.messages__actions a:before,
.messages__actions button:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.messages__actions a:hover:before,
.messages__actions button:hover:before {
  width: 100%;
}
.messages__list {
  display: flex;
  flex-direction: column;
}
.messages__list .iso_confirm {
  margin: 0 0 54px -54px;
  padding-left: 54px;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  background: url("../../images/check.svg") 0 0 no-repeat;
}

@media screen and (min-width: 768px) {
  .messages__inner {
    width: 760px;
    padding: 85px 100px 80px 120px;
    background: #fff;
  }
  .messages__actions {
    flex-direction: row;
    gap: 40px;
  }
}
@media screen and (min-width: 1220px) {
  .messages__list .iso_confirm {
    font-size: 33px;
  }
}
.overflow-hidden {
  overflow: hidden;
}

.add-to-cart__dialog {
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
}
.add-to-cart__dialog--open {
  opacity: 1;
  pointer-events: auto;
}
.add-to-cart__dialog-inner {
  position: relative;
  width: 450px;
  max-width: 100%;
  max-height: 100%;
  padding: 80px 20px 30px;
  background: #fff;
}
.add-to-cart__dialog-scroll {
  overflow-y: auto;
  max-height: calc(100vh - 160px);
}
.add-to-cart__dialog-close {
  position: absolute;
  inset: 30px 30px auto auto;
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  cursor: pointer;
}
.add-to-cart__dialog-close svg {
  width: 100%;
  height: 100%;
}
.add-to-cart__title {
  margin: 0 0 30px;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
}
.add-to-cart__options {
  max-width: 300px;
  margin: 0 auto;
}
.add-to-cart__options legend {
  display: none;
}
.add-to-cart__options > label {
  display: block;
  padding: 10px 0;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
}
.add-to-cart__options .radio_container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 0;
  border-bottom: 2px solid #002d40;
  padding: 0 !important;
}
.add-to-cart__options .radio_container > span {
  padding: 10px;
  border-top: 2px solid #E3E3E3;
  font-family: "Work Sans", sans-serif;
}
.add-to-cart__price {
  margin: 1em;
  text-align: center;
  font-family: "freight-text-pro", serif;
  font-size: 39px;
  line-height: 46px;
  letter-spacing: 0.4px;
}
.add-to-cart__actions {
  display: flex;
  justify-content: center;
}
.add-to-cart__actions button {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 25px;
  border: 2px solid #002d40;
  background: none;
  cursor: pointer;
  transition: background-color 0.1s ease;
}
.add-to-cart__actions button:hover {
  color: var(--magazine-text);
  background-color: var(--magazine-color);
}
.add-to-cart__actions button svg {
  width: 35px;
}
form:invalid .add-to-cart__actions button {
  opacity: 0.6;
  cursor: default;
}
form:invalid .add-to-cart__actions button:hover {
  color: inherit;
  background-color: inherit;
}

@media screen and (min-width: 1220px) {
  .add-to-cart__title {
    font-size: 33px;
  }
  .add-to-cart__dialog-inner {
    width: 760px;
    padding: 80px;
  }
  .add-to-cart__dialog-close {
    width: 30px;
    height: 30px;
  }
  .add-to-cart__options {
    max-width: 450px;
  }
  .add-to-cart__options > label {
    padding: 20px 0;
  }
  .add-to-cart__options .radio_container > span {
    padding: 20px;
  }
  .add-to-cart__actions button {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 25px 50px;
  }
  .add-to-cart__actions button svg {
    width: 45px;
  }
}
.module-magazine {
  margin-top: 30px;
}
.module-magazine__header {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}
.module-magazine__header .image_container {
  position: relative;
}
.module-magazine__header .image_container:before {
  content: "";
  position: absolute;
  top: 0;
  left: -71px;
  display: block;
  width: 351px;
  height: 397px;
  background: url("../../images/magazin.png") 0 0/100% 100% no-repeat;
  pointer-events: none;
}
.module-magazine__header .image_container img {
  width: 100%;
  max-width: 280px;
}
.module-magazine__header .add-to-cart__button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 25px;
  white-space: nowrap;
  border: 2px solid #002d40;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.1s ease, color 0.1s ease;
}
.module-magazine__header .add-to-cart__button svg {
  width: 45px;
  height: auto;
  margin-right: 20px;
}
.module-magazine__header .add-to-cart__button svg .check {
  stroke: #fff;
  transition: stroke 0.1s ease;
}
.module-magazine__header .add-to-cart__button:hover {
  color: var(--magazine-text);
  background-color: var(--magazine-color);
}
.module-magazine__header .add-to-cart__button:hover svg .check {
  stroke: var(--magazine-color);
}
.module-magazine__header .add-to-cart__button--sticky {
  position: fixed;
  z-index: 100;
  right: 20px;
  bottom: 20px;
  display: block;
  width: 65px;
  height: 65px;
  padding: 4px 0 0 !important;
  border-radius: 85px;
}
.module-magazine__header .add-to-cart__button--sticky span {
  position: absolute;
  opacity: 0;
  font-size: 0;
}
.module-magazine__header .add-to-cart__button--sticky svg {
  width: 60%;
  margin: 0;
}
.module-magazine__link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin: 20px 0 0;
}
.module-magazine__link a, .module-magazine__link button {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.module-magazine__link a:before, .module-magazine__link button:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.module-magazine__link a:hover:before, .module-magazine__link button:hover:before {
  width: 100%;
}
.module-magazine__title {
  margin-top: 0 !important;
}
.module-magazine__description {
  margin-top: 60px;
  padding: 2em 0;
  position: relative;
  color: var(--magazine-text);
}
.module-magazine__description:before {
  content: "";
  position: absolute;
  left: -27px;
  right: -27px;
  top: 0;
  bottom: 0;
  background: var(--magazine-color);
  z-index: -1;
}
@media screen and (min-width: 1220px) {
  .module-magazine__description:before {
    left: -70px;
    right: -70px;
  }
}
@media screen and (min-width: 1440px) {
  .module-magazine__description:before {
    left: calc(-50vw + 650px);
    right: calc(-50vw + 650px);
  }
}
.module-magazine__description p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}
.module-magazine__description button {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.module-magazine__description button:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.module-magazine__description button:hover:before {
  width: 100%;
}
.module-magazine__description--more p {
  overflow: unset;
  display: block;
  -webkit-line-clamp: unset;
}
.module-magazine__description--more button {
  display: none;
}
.module-magazine__article {
  padding: 0 0 60px;
}
.module-magazine__article:last-child {
  border-bottom: none;
}
.module-magazine__article .image_container {
  margin-bottom: 34px;
}
.module-magazine__article-title {
  margin: 0 0 30px !important;
}
.module-magazine__article-text {
  font-family: "freight-text-pro", serif;
}
.module-magazine__article-preview {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.module-magazine__article-preview:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.module-magazine__article-preview:hover:before {
  width: 100%;
}
.module-magazine__author {
  margin: 50px 0;
}
.module-magazine__author-text {
  margin-bottom: 25px;
  border-top: 1px solid #002d40;
}
.module-magazine__author img {
  border: 1px solid #002d40;
}
.module-magazine__videos {
  margin: 75px 0 0;
  padding: 0 20px 75px 0;
  position: relative;
}
.module-magazine__videos:before {
  content: "";
  position: absolute;
  left: -27px;
  right: -27px;
  top: 0;
  bottom: 0;
  background: #FAF8F8;
  z-index: -1;
}
@media screen and (min-width: 1220px) {
  .module-magazine__videos:before {
    left: -70px;
    right: -70px;
  }
}
@media screen and (min-width: 1440px) {
  .module-magazine__videos:before {
    left: calc(-50vw + 650px);
    right: calc(-50vw + 650px);
  }
}
.module-magazine__video-headline {
  margin-top: 0 !important;
  padding-top: 55px;
}
.module-magazine__video-player {
  display: grid;
  overflow: hidden;
  max-height: 600px;
  background: #000;
  box-shadow: 20px 20px var(--magazine-color);
}
.module-magazine__video-splash {
  position: relative;
  display: block;
}
.aspect--16\:9 .module-magazine__video-splash {
  aspect-ratio: 16/9;
}
.module-magazine__video-splash .aspect--16\:10 {
  aspect-ratio: 16/10;
}
.module-magazine__video-splash .aspect--21\:9 {
  aspect-ratio: 21/9;
}
.module-magazine__video-splash .aspect--4\:3 {
  aspect-ratio: 4/3;
}
.module-magazine__video-splash .aspect--3\:2 {
  aspect-ratio: 3/2;
}
.module-magazine__video-splash figure {
  width: 100%;
  height: 0;
}
.module-magazine__video-splash img {
  width: 100%;
  height: auto;
  object-position: center center;
  object-fit: cover;
}
.module-magazine__video-splash svg {
  position: absolute;
  inset: auto auto 15px 15px;
  width: 50px;
  height: 50px;
}
.module-magazine__video-description {
  margin-top: 50px;
  font-family: "freight-text-pro", serif;
}

@media screen and (min-width: 650px) {
  .module-magazine__header {
    max-width: none;
    margin: 0;
    padding: 0 25px;
    display: grid;
    grid: auto auto 1fr/auto 1fr;
  }
  .module-magazine__image {
    grid-row: 1/4;
    grid-column: 1/2;
  }
  .module-magazine__image .image_container {
    width: 280px;
    margin-right: 50px;
  }
  .module-magazine__issue {
    grid-row: 1/2;
    grid-column: 2/3;
  }
  .module-magazine__title {
    grid-row: 2/3;
    grid-column: 2/3;
  }
  .module-magazine__author {
    display: flex;
    gap: 30px;
    margin-top: 100px;
  }
  .module-magazine__author-text {
    width: 50%;
    border-top: 1px solid #002d40;
  }
  .module-magazine__video-splash svg {
    inset: auto auto 25px 25px;
    width: 100px;
    height: 100px;
  }
}
@media screen and (min-width: 768px) {
  .module-magazine {
    margin-top: 90px;
  }
  .module-magazine__header {
    padding: 0 75px;
  }
  .module-magazine__header .image_container {
    margin-right: 100px;
  }
  .module-magazine__header .add-to-cart__button--sticky {
    right: 40px;
    bottom: 40px;
    width: 85px;
    height: 85px;
  }
  .module-magazine__description {
    padding: 75px;
  }
  .module-magazine__articles {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px;
  }
  .module-magazine__article {
    width: 50%;
    padding: 30px;
    font-size: 20px;
    line-height: 30px;
  }
  .module-magazine__article-author {
    margin: 0 0 23px;
    font-size: 17px;
    line-height: 23px;
  }
  .module-magazine__videos {
    display: grid;
    grid-template: auto/1fr 1fr;
    gap: 60px;
    margin: 130px auto 0;
    padding: 70px 20px 140px 0;
  }
  .module-magazine__video-headline, .module-magazine__video-container {
    max-width: 800px;
    margin: 0 auto;
  }
  .module-magazine__video-description {
    margin-top: 70px;
  }
  .module-magazine__videos--total1 {
    display: block;
  }
}
@media screen and (min-width: 1220px) {
  .module-magazine__header .image_container:before {
    left: -91px;
    width: 471px;
    height: 538px;
  }
  .module-magazine__header .image_container img {
    max-width: 380px;
  }
  .module-magazine__header .image_container {
    width: 380px;
  }
  .module-magazine__header .add-to-cart__button {
    padding: 25px 50px;
  }
  .module-magazine__link {
    margin-top: 50px;
  }
  .module-magazine__title {
    font-size: 96px !important;
    line-height: 110px !important;
  }
  .module-magazine__description {
    margin-top: 160px;
    padding: 140px 75px 160px;
  }
  .module-magazine__description p {
    font: 43px/60px "freight-text-pro", serif;
  }
  .module-magazine__articles {
    margin: 0 -60px;
  }
  .module-magazine__article {
    padding: 60px;
  }
  .module-magazine__videos {
    column-gap: 120px;
  }
}
.module-list__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 20px;
}
.module-list__header h2 {
  margin: 0 0 20px !important;
  padding: 8px 0;
}
.module-list__filter {
  position: relative;
  display: none;
  float: right;
  margin-left: 50px;
  padding: 8px 40px 8px 15px;
  font-weight: 500;
  border-bottom: 2px solid #002d40;
}
.module-list__filter.active {
  display: flex;
}
.module-list__filter::after {
  content: "";
  position: absolute;
  right: 15px;
  top: 14px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 2px solid #002d40;
  border-bottom: 2px solid #002d40;
  transform: rotateZ(45deg);
  pointer-events: none;
}
.module-list__filter h3 {
  margin: 0 !important;
}
.module-list__filter select {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.module-list__items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -27px;
}
.module-list__item {
  margin: 35px 45px;
}
.module-list .image_container {
  position: relative;
  margin-bottom: 30px;
}
.module-list .image_container:before {
  content: "";
  position: absolute;
  top: 0;
  left: -71px;
  display: block;
  width: 351px;
  height: 397px;
  background: url("../../images/magazin.png") 0 0/100% 100% no-repeat;
  pointer-events: none;
}
.module-list .image_container img {
  width: 280px;
  max-width: none;
}
.module-list .add-to-cart {
  float: right;
  margin-left: 20px;
}
.module-list .add-to-cart__button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.1s ease;
}
.module-list .add-to-cart__button:hover {
  color: var(--magazine-color);
}
.module-list .add-to-cart__button svg {
  width: 45px;
  height: 40px;
}
.module-list__name {
  margin: 0 0 12px !important;
  font: 16px/26px "freight-text-pro", serif !important;
}
.module-list__link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 10px 0 0;
}
.module-list__link a, .module-list__link button {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  line-height: 18px;
}
.module-list__link a:before, .module-list__link button:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.module-list__link a:hover:before, .module-list__link button:hover:before {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .module-list__link a, .module-list__link button {
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (min-width: 768px) {
  .module-list__header {
    margin-top: 90px;
    margin-bottom: 50px;
  }
  .module-list__items {
    display: grid;
    grid: auto-flow/1fr 1fr;
    margin-left: 0;
    margin-right: 0;
  }
  .module-list__item {
    max-width: 280px;
    margin: 40px 50px;
  }
  .module-list__name {
    font: 20px/24px "freight-text-pro", serif !important;
  }
}
@media screen and (min-width: 1220px) {
  .module-list__headline {
    margin-left: -70px;
    margin-right: -70px;
  }
  .module-list__filter {
    padding-right: 80px;
  }
  .module-list__filter::after {
    width: 20px;
    height: 20px;
  }
  .module-list__items {
    grid: auto-flow/1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1440px) {
  .module-list__item {
    margin-left: 70px;
    margin-right: 70px;
  }
}
.product-gallery {
  display: flex;
  justify-content: center;
}
.product-gallery > div {
  position: relative;
  margin: 60px 40px 120px;
}
.product-gallery figure {
  display: inline-block;
  position: relative;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.3019607843);
  transform: matrix(1, -0.02, 0.02, 1, 0, 0);
  z-index: 2;
}
.product-gallery figure:first-child {
  position: absolute;
  top: 20px;
  left: -20px;
  transform: matrix(1, -0.09, 0.09, 1, 0, 0);
  z-index: 3;
}
.product-gallery figure:last-child {
  position: absolute;
  top: -20px;
  left: 20px;
  transform: matrix(1, 0.05, -0.05, 1, 0, 0);
  z-index: 1;
}

.flipbook {
  position: relative;
  display: inline-block;
  line-height: 0;
}
.flipbook figure {
  display: inline-block;
  background-color: #fff;
  transition: box-shadow 0.25s ease-in-out;
}
.flipbook figure picture {
  transform-style: preserve-3d;
  perspective: 1200px;
  display: inline-block;
}
.flipbook:hover figure {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}
.flipbook:active figure {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.15s ease-in-out;
}
.flipbook figure img {
  border-radius: 0;
  -webkit-transform-origin: left;
  transform-origin: left;
  transition: transform 0.25s;
  cursor: pointer;
  /* height: 300px; */
}
.flipbook:hover figure img {
  transform: rotateY(-17deg) rotateZ(0deg);
}
.flipbook:active figure img {
  transform: rotateY(-14deg) rotateZ(0deg);
}

.flipbook-modal {
  position: fixed;
  inset: 0 0 0 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
}
.flipbook-modal__close {
  position: absolute;
  inset: 20px 20px auto auto;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
}
.flipbook-modal__close::before, .flipbook-modal__close::after {
  content: "";
  position: absolute;
  inset: 0 auto auto 13px;
  display: block;
  width: 4px;
  height: 30px;
  background: #ccc;
}
.flipbook-modal__close::before {
  transform: rotateZ(45deg);
}
.flipbook-modal__close::after {
  transform: rotateZ(-45deg);
}
.flipbook-modal__close:hover::before, .flipbook-modal__close:hover::after {
  outline: 1px solid rgba(255, 255, 255, 0.3);
}
.flipbook-modal__container,
.flipbook-modal iframe {
  width: 100%;
  height: 100%;
}
.flipbook-modal__container img,
.flipbook-modal iframe img {
  width: 99px;
  height: 99px;
  margin-top: calc(50vh - 49px);
  margin-left: calc(50vw - 49px);
}

body.layout--flipbook {
  overflow: hidden;
}

.account-navigation {
  margin-bottom: 45px;
}
.account-navigation ul {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.account-navigation a {
  display: block;
  padding: 7px 20px;
  font-size: 14px;
  line-height: 1.3;
  border: 1px solid transparent;
  border-radius: 100px;
}
.account-navigation a:hover, .account-navigation a.active, .account-navigation a.trail {
  border-color: var(--magazine-color);
}
@media screen and (min-width: 768px) {
  .account-navigation ul {
    flex-direction: row;
  }
  .account-navigation a {
    font-size: 18px;
  }
}
@media screen and (min-width: 1220px) {
  .account-navigation {
    margin-left: 70px;
    margin-right: 70px;
  }
}

.mod_iso_orderhistory {
  max-width: 960px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.mod_iso_orderhistory .order-status {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.mod_iso_orderhistory .toggler,
.mod_iso_orderhistory .toggler > button {
  display: flex;
  align-items: center;
}
.mod_iso_orderhistory .toggler .order-status,
.mod_iso_orderhistory .toggler > button .order-status {
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  margin-left: -16px;
  margin-right: 10px;
}
.mod_iso_orderhistory .toggler .order-title,
.mod_iso_orderhistory .toggler > button .order-title {
  overflow: hidden;
  text-overflow: ellipsis;
}
.mod_iso_orderhistory .order-details {
  margin: 2em 0;
  border-bottom: 1px solid var(--magazine-color);
}
.mod_iso_orderhistory .order-details__info > div {
  margin-bottom: 0.5em;
}
.mod_iso_orderhistory .order-details__info > div > div:first-child {
  flex-shrink: 0;
  width: 200px;
  font-weight: 600;
}
.mod_iso_orderhistory .order-details__item {
  display: flex;
  gap: 30px;
  padding: 30px 0;
  border-top: 1px solid var(--magazine-color);
}
.mod_iso_orderhistory .order-details__headline {
  font-weight: 500;
}
.mod_iso_orderhistory .order-details__download {
  margin-top: 0.5em;
  font-size: 0.8em;
}
.mod_iso_orderhistory .order-details__download--available {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.mod_iso_orderhistory .order-details__download--available:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.mod_iso_orderhistory .order-details__download--available:hover:before {
  width: 100%;
}
.mod_iso_orderhistory .order-details__download--remaining {
  display: inline-block;
  padding: 0 10px;
  background: #EBD47F;
}
@media screen and (min-width: 768px) {
  .mod_iso_orderhistory .order-details {
    margin: 50px 10px;
  }
  .mod_iso_orderhistory .order-details__info > div {
    display: flex;
  }
  .mod_iso_orderhistory .order-details__info > div > div:first-child {
    flex-shrink: 0;
    width: 200px;
    font-weight: 600;
  }
}

.mod_two_factor .submit_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin: 20px 0 0;
}
.mod_two_factor .submit_container a, .mod_two_factor .submit_container button {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.mod_two_factor .submit_container a:before, .mod_two_factor .submit_container button:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 50%;
}
.mod_two_factor .submit_container a:hover:before, .mod_two_factor .submit_container button:hover:before {
  width: 100%;
}

.mod_iso_cart,
.mod_iso_checkout {
  margin-top: 30px;
}
.mod_iso_cart table .name,
.mod_iso_checkout table .name {
  padding-bottom: 20px;
  font-size: 24px;
}
.mod_iso_cart table .name span,
.mod_iso_checkout table .name span {
  font-weight: 500;
}
.mod_iso_cart table .quantity input.text,
.mod_iso_checkout table .quantity input.text {
  width: 40px;
  height: 29px;
  padding: 0;
  border: none;
  border-bottom: 2px solid #E3E3E3;
  border-radius: 0;
  text-align: center;
}
.mod_iso_cart table .quantity input.text:focus,
.mod_iso_checkout table .quantity input.text:focus {
  outline: none;
  border-bottom-color: #012B3D;
}
.mod_iso_cart table .actions,
.mod_iso_checkout table .actions {
  text-align: right;
}
.mod_iso_cart table .actions a,
.mod_iso_checkout table .actions a {
  display: inline-flex;
  gap: 10px;
  padding: 7px 0 0;
  font-size: 17px;
  line-height: 1.35;
  transition: opacity 0.1s ease;
}
.mod_iso_cart table .actions a:hover,
.mod_iso_checkout table .actions a:hover {
  opacity: 0.6;
}
.mod_iso_cart table tr.border td,
.mod_iso_checkout table tr.border td {
  padding-top: 30px;
  border-top: 2px solid #E3E3E3;
}
.mod_iso_cart .submit_container,
.mod_iso_checkout .submit_container {
  display: flex;
  gap: 25px;
}
.mod_iso_cart .submit_container button,
.mod_iso_checkout .submit_container button {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
}
.mod_iso_cart .submit_container button:before,
.mod_iso_checkout .submit_container button:before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: currentColor;
  left: 25%;
  transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
  width: 50%;
}
.mod_iso_cart .submit_container button:hover:before,
.mod_iso_checkout .submit_container button:hover:before {
  left: 0;
  width: 100%;
}
.mod_iso_cart .submit_container button:last-child,
.mod_iso_checkout .submit_container button:last-child {
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  .mod_iso_cart table, .mod_iso_cart thead, .mod_iso_cart tbody, .mod_iso_cart tr, .mod_iso_cart th, .mod_iso_cart td, .mod_iso_checkout table, .mod_iso_checkout thead, .mod_iso_checkout tbody, .mod_iso_checkout tr, .mod_iso_checkout th, .mod_iso_checkout td {
    display: block;
  }
  .mod_iso_cart table, .mod_iso_checkout table {
    display: flex;
    flex-direction: column;
  }
  .mod_iso_cart table .mobile-hidden, .mod_iso_checkout table .mobile-hidden {
    display: none;
  }
  .mod_iso_cart table .image, .mod_iso_checkout table .image {
    display: none;
  }
  .mod_iso_cart table .grandtotal, .mod_iso_checkout table .grandtotal {
    font-weight: 500;
  }
  .mod_iso_cart table tr.product, .mod_iso_checkout table tr.product {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .mod_iso_cart table tr.product td:nth-child(1), .mod_iso_checkout table tr.product td:nth-child(1) {
    width: 100%;
  }
  .mod_iso_cart table tr.product td:nth-child(2), .mod_iso_checkout table tr.product td:nth-child(2) {
    display: none;
  }
  .mod_iso_cart table tr.product td.actions, .mod_iso_checkout table tr.product td.actions {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
  .mod_iso_cart table tr.product + tr.product, .mod_iso_checkout table tr.product + tr.product {
    padding-top: 20px;
    border-top: 1px solid #E3E3E3;
  }
  .mod_iso_cart table tr.before_border, .mod_iso_checkout table tr.before_border {
    padding-bottom: 30px;
  }
  .mod_iso_cart table tfoot tr, .mod_iso_checkout table tfoot tr {
    display: flex;
  }
  .mod_iso_cart table tfoot tr td, .mod_iso_checkout table tfoot tr td {
    flex-grow: 1;
  }
  .mod_iso_cart table tfoot tr td.price, .mod_iso_checkout table tfoot tr td.price {
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .mod_iso_cart table, .mod_iso_checkout table {
    width: 100%;
    border-bottom: 2px solid #E3E3E3;
  }
  .mod_iso_cart table .image, .mod_iso_checkout table .image {
    width: 140px;
    min-width: 140px;
    padding-left: 0;
    text-align: left;
    vertical-align: top;
  }
  .mod_iso_cart table .image .image_container, .mod_iso_checkout table .image .image_container {
    position: relative;
    margin-top: 10px;
    padding-left: 34px;
  }
  .mod_iso_cart table .image .image_container:before, .mod_iso_checkout table .image .image_container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 12px;
    display: block;
    width: 113px;
    height: 130px;
    background: url("../../images/magazin.png") 0 0/100% 100% no-repeat;
    pointer-events: none;
  }
  .mod_iso_cart table .image .image_container img, .mod_iso_checkout table .image .image_container img {
    width: 100%;
    max-width: 120px;
  }
  .mod_iso_cart table .name, .mod_iso_checkout table .name {
    height: 100px;
  }
  .mod_iso_cart table .subhead, .mod_iso_checkout table .subhead {
    height: 44px;
  }
  .mod_iso_cart table .price, .mod_iso_checkout table .price {
    width: 180px;
  }
  .mod_iso_cart table .price div, .mod_iso_checkout table .price div {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }
  .mod_iso_cart table .quantity, .mod_iso_checkout table .quantity {
    text-align: center;
  }
  .mod_iso_cart table tr.border td, .mod_iso_checkout table tr.border td {
    padding-top: 60px;
  }
  .mod_iso_cart table tr.before_border, .mod_iso_checkout table tr.before_border {
    padding-bottom: 0;
  }
  .mod_iso_cart table tr.before_border td,
  .mod_iso_cart table td.before_border, .mod_iso_checkout table tr.before_border td,
  .mod_iso_checkout table td.before_border {
    padding-bottom: 60px;
  }
  .mod_iso_cart .submit_container, .mod_iso_checkout .submit_container {
    margin-top: 100px;
  }
}
@media screen and (min-width: 1220px) {
  .mod_iso_cart table, .mod_iso_checkout table {
    font-size: 20px;
  }
  .mod_iso_cart table .image, .mod_iso_checkout table .image {
    width: 220px;
    min-width: 220px;
  }
  .mod_iso_cart table .image .image_container:before, .mod_iso_checkout table .image .image_container:before {
    left: 3px;
    width: 151px;
    height: 171px;
  }
}
.mod_iso_checkout .steps ol {
  display: flex;
  margin: 50px 0;
  padding: 0;
}
.mod_iso_checkout .steps li {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 1.16;
  text-align: center;
}
.mod_iso_checkout .steps li:not(:last-child)::before {
  content: "";
  position: relative;
  top: 10px;
  left: 50%;
  height: 2px;
  background-color: #e0e0e0;
  order: 1;
}
.mod_iso_checkout .steps li::after {
  content: "";
  position: relative;
  top: 16px;
  z-index: 1;
  display: block;
  width: 10px;
  height: 10px;
  background: #E3E3E3;
  border-radius: 50%;
  margin: 0 auto;
}
.mod_iso_checkout .steps li.active::before {
  top: 6px;
}
.mod_iso_checkout .steps li.active::after {
  top: 14px;
  width: 14px;
  height: 14px;
  background: var(--magazine-color);
}
.mod_iso_checkout .steps li.passed:before, .mod_iso_checkout .steps li.passed::after {
  background: var(--magazine-color);
}
.mod_iso_checkout #ctrl_paymentmethod {
  display: flex;
  flex-direction: column;
}
.mod_iso_checkout #ctrl_paymentmethod .note {
  display: block;
  font-size: 0.8em;
}
.mod_iso_checkout #ctrl_paymentmethod .note p:first-child {
  margin-top: 0;
}
.mod_iso_checkout #ctrl_paymentmethod .note a {
  text-decoration: underline;
}
.mod_iso_checkout .info_container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 25px;
  line-height: 1.25;
}
.mod_iso_checkout .info_container h3 {
  margin: 0 0 1em;
}
.mod_iso_checkout .info_container h4 {
  margin: 0 0 0.5em;
}
.mod_iso_checkout .info_container a {
  text-decoration: underline;
}
.mod_iso_checkout .info_container .edit {
  float: right;
  transition: color 0.1s ease;
}
.mod_iso_checkout .info_container .edit:hover {
  color: var(--magazine-color);
}
.mod_iso_checkout .info_container .edit svg {
  width: 20px;
  height: 20px;
}
.mod_iso_checkout .info_container > div {
  flex-grow: 1;
  padding: 20px;
  background: #FAF8F8;
}
.mod_iso_checkout .info_container .address {
  width: 100%;
}
.mod_iso_checkout .info_container .address-group {
  display: flex;
  gap: 100px;
}
.mod_iso_checkout .orderconditions {
  margin: 0 0 2em;
}

@media screen and (min-width: 768px) {
  .mod_iso_checkout .info_container {
    gap: 37.5px;
    margin-bottom: 100px;
  }
  .mod_iso_checkout .info_container > div {
    padding: 40px 50px;
  }
  .mod_iso_checkout .address_new {
    display: flex;
    flex-wrap: wrap;
    gap: 0 25px;
  }
  .mod_iso_checkout .address_new > div {
    width: calc(50% - 12.5px);
  }
  .mod_iso_checkout .orderconditions {
    margin: 2em 0 0;
  }
}
@media screen and (min-width: 1220px) {
  .mod_iso_checkout .info_container .edit svg {
    width: 30px;
    height: 30px;
  }
  .mod_iso_checkout .address_new {
    gap: 0 50px;
  }
  .mod_iso_checkout .address_new > div {
    width: calc(50% - 25px);
  }
}