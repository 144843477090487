.section-social {
    &__inside {
        padding: 60px 0 40px;
        text-align: center;
        @extend .nav-list;
        font-size: 15px;
        line-height: 18px;
    }

    &__title {
        margin: 0 0 1em 0;
    }

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    &__icon {
        display: block;
        width: 25px;
        height: 25px;
        background: url("../../images/social.svg") 0 0/25px 100px no-repeat;
        text-indent: -999em;
        transition: opacity .1s ease-in-out;

        &:hover {
            opacity: .6;
        }

        &--facebook {
            background-position: 0 0;
        }

        &--twitter {
            background-position: 0 -25px;
        }

        &--linkedin {
            background-position: 0 -50px;
        }

        &--email {
            background-position: 0 -75px;
        }
    }
}

@include screen(medium, '.section-social') {
    &__inside {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 100px;
        font-size: 18px;
        line-height: 21px;
    }

    &__title {
        margin: 0 15px 0 0;
    }
}
